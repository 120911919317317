.qadpt-feedbackpopup{
  width: calc(100vh - 270px);
  height: calc(100vh - 190px);
  border-radius: 8px !important;
  position: relative !important;
  padding: 20px;
  overflow: hidden ! important;
  margin-top: 76px !important;
  
.qadpt-feedback-header {
    margin-bottom: 10px;
    display: flex;
  
    .qadpt-feedback-title {
      font-size: 18px;
      font-weight: 600;
  
      .qadpt-feedback-subtitle {
        font-size: 12px;
        font-weight: 400;
        color: #c3c3c3;
        margin-top: 8px; 
        text-align: center;
      }
    }
  
    .qadpt-close-icon {
      position: absolute !important;
      right: 15px;
  
      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
  
  .qadpt-content {
    height: calc(100vh - 240px);
    overflow: auto;
    .qadpt-container {
      margin-top: 6px;
      font-size: 12px;
      font-weight: 400;
  
      .qadpt-label {
        font-weight: 600;
      }
    }
    
    .qadpt-rating {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      overflow: hidden;
  
      .qadpt-rating-stars {
        .MuiRating-icon {
          font-size: 25px;
          transition: none;
          padding: 0 10px;
        }
  
        .MuiRating-iconHover {
          color: rgba(255, 255, 255, 1); 
          transform: none; 
          transition: none; 
          opacity: 1;
        }
  
        .MuiRating-iconFilled {
          color: rgba(95, 158, 160, 1); 
        }
  
        .MuiRating-iconEmpty {
          color: rgba(34, 34, 34, 1); 
        }
      }
    }
  
    .qadpt-textarea {
      margin-top: 10px;
  
      textarea {
        height: 100px;
      }
    }
  
    .qadpt-upload-container {
      margin-top: 10px;
      text-align: center;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 5px;
  
      .qadpt-upload-button {
        background-color: white !important;
        color: black !important;
        box-shadow: none !important;
        width: 100%;
        height: 20px;
        text-transform: capitalize !important;
        padding: 0 !important;
        font-weight: 600 !important;
        font-size: 12px !important;
      }
  
      .qadpt-upload-description {
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 11px;
        text-transform: capitalize;
      }
    }
    .qadpt-file-list {
      .qadpt-list-item {
        border: 1px solid #ccc;
        padding: 2px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        margin-top: 5px;
        border-radius: 5px;
      }
      
      .qadpt-file-name {
        margin: 7px;
        font-size: 14px;
      }
      
      .qadpt-file-actions {
        display: flex;
        align-items: center;
        svg{
          height: 21px;
          width: 21px;
        }
      }
      
      
      .qadpt-fileerror {
        color: var(--error-color);
        font-size: 12px;
        margin-top: -10px;
        margin-left: 61px;
      }
      }
  }
  .qadpt-footer {
    button {
      float: right;
    }

    .qadpt-submit-button {
      background-color: var(--button-bg-color) !important;
      color: var(--white-color) !important;
      border-radius: 10px !important;
      top:10px;

      &.qadpt-submit-disabled {
        background-color: #a5c3c5 !important; 
        color: white !important;
      }
    }
  }
}

.qadpt-feedback-dialog {
  padding: 20px;
  border-radius: 8px;
  width: 320px;
  text-align: center;
  height: 423px;

  .qadpt-dialogcontent {
    position: relative;
    top: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .qadpt-message {
      font-weight: bold;
      font-size: 15px;
    }

    .qadpt-actionbutton {
      display: flex;
      align-items: center;
      place-content: center;

      button {
        background: var(--button-bg-color);
        color: var(--white-color) !important;
        border-radius: 10px;
      }
    }
  }
}
