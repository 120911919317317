.userEditpopup h1 {
	background-image: linear-gradient(to right, rgb(37, 81, 181), rgba(0, 0, 0, 0.7));
	font-size: 17px;
	color: var(--white-color);
	height: 44px;
	padding: 5px 18px;
	margin: 6px -194px 17px;
	text-align: center;
	width: 340px;
	margin-right: 101px;
	margin-left: -21px;
}
.userEditpopup {
	z-index: 16000;
	width: 334px !important;
	height: 35vh;
	top: 223px;
	right: 683px;
	padding: 0px 20px;
	position: fixed !important;
	display: block !important;
	background: var(--white-color) !important;
	box-shadow: 0 3px 4px #000000;
}
.cancel {
	position: relative;
	background: rgb(255, 255, 255);
	color: rgb(0, 123, 255);
	border: 1px solid rgb(0, 123, 255);
	margin-right: 173px;
	right: -71px;
	top: 64px;
	position: absolute;
	right: 37px;
	z-index: 99;
	top: 142px;

	border-radius: 5px;
	cursor: pointer;
	font-size: 14px;
	padding: 7px 21px;
}
.deactivate {
	position: relative;
	right: -147px;
	top: 65px;
	position: absolute;
	right: 37px;
	z-index: 99;
	top: 67px;
	background-color: #007bff;
	border: none;
	border-radius: 5px;
	color: var(--white-color);
	cursor: pointer;
	font-size: 14px;
	padding: 7px 21px;
}
.userButton {
	position: absolute;
	z-index: 99;
	background-color: #007bff;
	border: none;
	border-radius: 5px;
	color: var(--white-color);
	cursor: pointer;
	font-size: 14px;
	padding: 7px 21px;
	position: relative;
	right: -147px;
	top: 66px;
}

.createButton {
	position: absolute;
	right: 37px;
	z-index: 99;
	top: 67px;
	background-color: rgb(95, 158, 160);
	border: none;
	border-radius: 5px;
	color: var(--white-color);
	cursor: pointer;
	font-size: 14px;
	padding: 7px 21px;
}
.qadpt-org-filter {
	
	// .MuiPaper-root.MuiAutocomplete-paper {
	// 	margin-top: 70px;
	// 	left: 40px;
	// }
	.MuiFormControl-root{

	 .MuiFormLabel-root{
		padding-left: 5px;
		
		&.MuiInputLabel-shrink {
			left: 6px;
			top: 9px !important;		
		}
	}
	.MuiInputBase-root.Mui-focused{
		input{
			padding-top: 5px !important;
		}
		.MuiAutocomplete-endAdornment{
			padding-top: 5px !important;

		}
	}
	fieldset{
		margin: 0 10px;
	}
}

}
.qadpt-orgcont {
	.qadpt-head {
		border-bottom: 1px solid var(--border-color);
		margin: 0 -20px;
		padding: 20px 20px 10px 20px;
		display: flex;
		align-items: center;
		.qadpt-title-sec {
			width: calc(100% - 200px);
			.qadpt-title {
				font-weight: 600;
				font-size: 22px;
				line-height: 20px;
			}
		}
		.qadpt-right-part {
			text-align: right;
			width: 210px;
			.MuiInputLabel-outlined.MuiInputLabel-shrink {
				top: 7px !important;
			}
			.MuiAutocomplete-root {
				padding: 5px;
			}
		}
	}
}
.qadpt-org-grd {
	border-color: var(--grid-border-color) !important;
	top: 10px;
	position: relative !important;
	height: calc(100vh - 127px) !important;
	.MuiDataGrid-virtualScroller {
		height: calc(100vh - 237px) !important;
	}
	.MuiDataGrid-footerContainer .MuiTablePagination-root .MuiInputBase-root .MuiSelect-select.MuiTablePagination-select {
		padding-right: 24px !important;
	}
}


  