.title {
	font-family: Syncopate;
	font-size: 19px;
	font-weight: 700;
	line-height: 19.78px;
	letter-spacing: 0.30000001192092896px;
	text-align: center;
	color: rgba(95, 158, 160, 1);
}

.welcomeback {
	font-family: Poppins;
	font-size: 24px;
	font-weight: 700;
	line-height: 36px;
	letter-spacing: 0.30000001192092896px;
	text-align: center;
	width: 188px;
	height: 36px;
	gap: 0px;
	opacity: 0px;
	color: rgba(34, 34, 34, 1);
}

.email {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-align: center;
	width: 44px;
	height: 24px;
	gap: 0px;
	opacity: 0px;
	color: rgba(68, 68, 68, 1);
}

.qadpt-superadminlogin,
.qadpt-resetpassword {
	display: flex !important;
	flex-direction: column;
	align-items: center;
	background-color: #f9f1f0;
	padding: 20px;
	max-width: 100% !important;
	height: 100%;

	.qadpt-brand-logo {
		margin: 40px;
		.qadpt-brand-logo-img {
			width: 300px;
			height: auto;
		}
	}

	.qadpt-welcome-message {
		margin-top: 60px;
		margin-bottom: 20px;
		text-align: center;

		.qadpt-welcome-message-text {
			font-family: "Arial", sans-serif;
			color: #333;
			font-weight: bold;
			font-size: 22px;
		}
	}

	.qadpt-login-form {
		width: 100%;
		max-width: 300px;
		text-align: center;
		margin-top: 30px;

		.qadpt-form-label {
			text-align: left;
			color: #444;
			margin-top: 10px;
			font-size: 14px;
			span {
				cursor: pointer;
			}
		}

		.qadpt-custom-input {
			border-radius: 8px !important;
			border: 1px solid #ccc;
			margin-top: 8px;
			width: 100%;

			background-color: var(--white-color);
		}
		.qadpt-text-danger {
			color: #d9534f;
			font-size: 0.9rem;
		}

		// .qadpt-btn-default {
		// 	width: 100%;
		// 	background-color: rgba(95, 158, 160, 1);
		// 	color: white;
		// 	padding: 10px;
		// 	border-radius: 15px;
		// 	font-size: 1rem;
		// 	margin-top: 30px;

		// 	&:hover {
		// 		background-color: rgba(95, 158, 160, 1);
		// 		color: white !important;
		// 	}
		// }

		.qadpt-button-text {
			font-family: "Arial", sans-serif;
			font-size: 1rem;
			font-weight: 500;
			text-transform: none;
		}
		.MuiOutlinedInput-notchedOutline {
			border: 0 !important;
		}
		.qadpt-passwordhint {
			width: 300px;
			height: 50px;
			border-radius: 6px;
			border: 1px solid rgb(220, 221, 225);
			margin-top: 10px;

			.qadpt-passwordhint-text {
				text-align: left;
				margin-left: 13px;
				font-size: 14px;
			}

			.qadpt-passwordhint-container {
				display: flex;
				align-items: center;
				margin-left: 13px;
				gap: 10px;
				svg {
					font-size: 18px !important;
				}
			}

			.qadpt-checkicon-valid {
				color: rgba(95, 158, 160, 1);
				font-size: 18px;
			}

			.qadpt-passwordhint-item {
				font-size: 12px;
				line-height: 21px;
			}
		}
	}

	.qadpt-login-footer {
		position: absolute;
		bottom: 10px;
		left: 42%;
		font-size: 14px;
		text-align: center;

		.qadpt-footer-text {
			color: rgba(95, 158, 160, 1);

			.qadpt-footer-link {
				color: rgba(95, 158, 160, 1);
				text-decoration: none;
				margin: 0 10px;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
.qadpt-resetpassword {
	.qadpt-pwd-changed {
		text-align: center;
		margin-top: 50%;
	}

	.qadpt-pwd-title {
		font-size: 20px !important;
		font-weight: 600 !important;
	}

	.qadpt-changed-msg {
		width: 300px;
		margin-bottom: -10px !important;
	}
}
