.common-icon {
	text-align: center;
	width: 60px;
	height: 60px;
	padding-top: 10px;
	margin: auto;
	background: #ccc;
	border-radius: 100%;
}

.common-icon:before {
	font-family: "qadapt-icons" !important;
	font-size: 36px;
	font-weight: 100;
	color: #444;
}

.fa-analysis:before {
	content: "\a003";
}
.fa-attendance-machine:before {
	content: "\a004";
}
.fa-bike-insurance:before {
	content: "\a005";
}
.fa-bill-receipt:before {
	content: "\a006";
}
.fa-business-communication:before {
	content: "\a007";
}
.fa-business-investment:before {
	content: "\a008";
}
.fa-business-management:before {
	content: "\a009";
}
.fa-businessman-with-briefcase:before {
	content: "\a010";
}
.fa-business-presentation:before {
	content: "\a011";
}
.fa-business-professional:before {
	content: "\a012";
}
.fa-business-profit:before {
	content: "\a013";
}
.fa-business-relationship:before {
	content: "\a014";
}
.fa-buyer:before {
	content: "\a015";
}
.fa-career:before {
	content: "\a016";
}
.fa-car-insurance:before {
	content: "\a017";
}
.fa-car-repair-mechanic:before {
	content: "\a018";
}
.fa-cashier:before {
	content: "\a019";
}
.fa-ceo:before {
	content: "\a020";
}
.fa-client:before {
	content: "\a021";
}
.fa-clients:before {
	content: "\a022";
}
.fa-closed:before {
	content: "\a023";
}
.fa-contract:before {
	content: "\a024";
}
.fa-core-values:before {
	content: "\a025";
}
.fa-corporate:before {
	content: "\a026";
}
.fa-credit-card-swipe:before {
	content: "\a027";
}
.fa-crm-browser:before {
	content: "\a028";
}
.fa-customer-experience:before {
	content: "\a029";
}
.fa-customer-journey:before {
	content: "\a030";
}
.fa-data-analytics:before {
	content: "\a031";
}
.fa-data-science:before {
	content: "\a032";
}
.fa-document-application:before {
	content: "\a033";
}
.fa-document-application-woman:before {
	content: "\a034";
}
.fa-erp:before {
	content: "\a035";
}
.fa-factory-pollution:before {
	content: "\a036";
}
.fa-family-insurance:before {
	content: "\a037";
}
.fa-female-reporter-journalist:before {
	content: "\a038";
}
.fa-fire-insurance:before {
	content: "\a039";
}
.fa-food-industry:before {
	content: "\a040";
}
.fa-general-insurance:before {
	content: "\a041";
}
.fa-growing-market-analysis:before {
	content: "\a042";
}
.fa-gst:before {
	content: "\a043";
}
.fa-headquarter:before {
	content: "\a044";
}
.fa-health-insurance:before {
	content: "\a045";
}
.fa-hierarchy-management:before {
	content: "\a046";
}
.fa-hierarchy-management-task:before {
	content: "\a047";
}
.fa-home-insurance:before {
	content: "\a048";
}
.fa-import-product:before {
	content: "\a049";
}
.fa-improvement-performance:before {
	content: "\a050";
}
.fa-income-taxes:before {
	content: "\a051";
}
.fa-influencer:before {
	content: "\a052";
}
.fa-insight:before {
	content: "\a053";
}
.fa-inspection:before {
	content: "\a054";
}
.fa-insurance-protection:before {
	content: "\a055";
}
.fa-integration:before {
	content: "\a056";
}
.fa-interview:before {
	content: "\a057";
}
.fa-investor:before {
	content: "\a058";
}
.fa-invoice:before {
	content: "\a059";
}
.fa-job:before {
	content: "\a060";
}
.fa-job-search:before {
	content: "\a061";
}
.fa-male-reporter-journalist:before {
	content: "\a062";
}
.fa-management:before {
	content: "\a063";
}
.fa-manufacturing-production:before {
	content: "\a064";
}
.fa-market-research:before {
	content: "\a065";
}
.fa-market-share:before {
	content: "\a066";
}
.fa-mechanic:before {
	content: "\a067";
}
.fa-meeting:before {
	content: "\a068";
}
.fa-meeting-table:before {
	content: "\a069";
}
.fa-mind-map:before {
	content: "\a070";
}
.fa-money-transfer:before {
	content: "\a071";
}
.fa-new-product:before {
	content: "\a072";
}
.fa-newspaper:before {
	content: "\a073";
}
.fa-newspaper-jobs:before {
	content: "\a074";
}
.fa-office:before {
	content: "\a075";
}
.fa-online-survey:before {
	content: "\a076";
}
.fa-online-work:before {
	content: "\a077";
}
.fa-pending-work:before {
	content: "\a078";
}
.fa-person-insurance:before {
	content: "\a079";
}
.fa-pilot:before {
	content: "\a080";
}
.fa-planning:before {
	content: "\a081";
}
.fa-plumbing:before {
	content: "\a082";
}
.fa-power-plant:before {
	content: "\a083";
}
.fa-product-development:before {
	content: "\a084";
}
.fa-productivity:before {
	content: "\a085";
}
.fa-product-launch-release:before {
	content: "\a086";
}
.fa-project:before {
	content: "\a087";
}
.fa-project-management:before {
	content: "\a088";
}
.fa-project-management-timeline:before {
	content: "\a089";
}
.fa-project-manager:before {
	content: "\a090";
}
.fa-project-work:before {
	content: "\a091";
}
.fa-quality-control:before {
	content: "\a092";
}
.fa-receipt:before {
	content: "\a093";
}
.fa-remote-work:before {
	content: "\a094";
}
.fa-repairing:before {
	content: "\a095";
}
.fa-retail-shop:before {
	content: "\a096";
}
.fa-satisfaction:before {
	content: "\a097";
}
.fa-seller:before {
	content: "\a098";
}
.fa-service-desk:before {
	content: "\a099";
}
.fa-services:before {
	content: "\a100";
}
.fa-solution:before {
	content: "\a101";
}
.fa-strategist:before {
	content: "\a102";
}
.fa-successful-businessman:before {
	content: "\a103";
}
.fa-supervisor:before {
	content: "\a104";
}
.fa-supply-chain:before {
	content: "\a105";
}
.fa-tax-calculator:before {
	content: "\a106";
}
.fa-tax-cut:before {
	content: "\a107";
}
.fa-tax-return:before {
	content: "\a108";
}
.fa-team:before {
	content: "\a109";
}
.fa-team-meeting:before {
	content: "\a110";
}
.fa-technician:before {
	content: "\a111";
}
.fa-trade:before {
	content: "\a112";
}
.fa-user-network:before {
	content: "\a113";
}
.fa-value:before {
	content: "\a114";
}
.fa-vat:before {
	content: "\a115";
}
.fa-video-conference:before {
	content: "\a116";
}
.fa-virtual-meeting:before {
	content: "\a117";
}
.fa-meeting-room:before {
	content: "\a118";
}
.fa-workflow:before {
	content: "\a119";
}
.fa-working-hours:before {
	content: "\a120";
}
.fa-working-on-office:before {
	content: "\a121";
}
.fa-working-time:before {
	content: "\a122";
}
.fa-workplace:before {
	content: "\a123";
}
.fa-workshop:before {
	content: "\a124";
}
.fa-waiting-room-area:before {
	content: "\a125";
}
.fa-user-tie-solid:before {
	content: "\a127";
}
.fa-caret-up-solid:before {
	content: "\a128";
}
.fa-check-circle-solid:before {
	content: "\a129";
}
.fa-times-circle-solid:before {
	content: "\a130";
}
.fa-password-reset:before {
	content: "\a131";
}
.fa-password:before {
	content: "\a132";
}
.fa-reset-password:before {
	content: "\a133";
}
.fa-men-gear-circle:before {
	content: "\a134";
}
.fa-men-gear:before {
	content: "\a135";
}
.fa-light-ceilinglight:before {
	content: "\a147";
}
.fa-exclamation-circle-solid:before {
	content: "\a148";
}
.fa-add-multi-dtrecords:before {
	content: "\a149";
}
.fa-branch:before {
	content: "\a150";
}
.fa-deploy-log-download:before {
	content: "\a151";
}
.fa-deployment-log:before {
	content: "\a152";
}
.fa-deploy-rollback:before {
	content: "\a153";
}
.fa-dt-download:before {
	content: "\a154";
}
.fa-ds-filter:before {
	content: "\a155";
}
.fa-dt-functions:before {
	content: "\a156";
}
.fa-import-dtrecords:before {
	content: "\a157";
}
.fa-dt-index:before {
	content: "\a158";
}
.fa-ds-join:before {
	content: "\a159";
}
.fa-manage-stages:before {
	content: "\a160";
}
.fa-records:before {
	content: "\a161";
}
.fa-regular-view:before {
	content: "\a162";
}
.fa-dt-sync:before {
	content: "\a163";
}
.fa-timeline-view:before {
	content: "\a164";
}
.fa-manage-apps:before {
	content: "\a165";
}
.fa-box-key:before {
	content: "\a166";
}
.fa-deploy-branch:before {
	content: "\a167";
}
.fa-version-manage:before {
	content: "\a168";
}
.fa-add-ds:before {
	content: "\a169";
}
.fa-add-dttable:before {
	content: "\a170";
}
.fa-admenu:before {
	content: "\a171";
}
.fa-apps:before {
	content: "\a172";
}
.fa-appstore:before {
	content: "\a173";
}
.fa-crreport:before {
	content: "\a174";
}
.fa-crview:before {
	content: "\a175";
}
.fa-datasource:before {
	content: "\a176";
}
.fa-dsref:before {
	content: "\a177";
}
.fa-dttable:before {
	content: "\a178";
}
.fa-imp-user:before {
	content: "\a179";
}
.fa-ip-white:before {
	content: "\a180";
}
.fa-more:before {
	content: "\a181";
}
.fa-multi-user:before {
	content: "\a182";
}
.fa-pref:before {
	content: "\a183";
}
.fa-config-sms:before {
	content: "\a184";
}
.fa-ham-menu:before {
	content: "\a185";
}
.fa-myroles:before {
	content: "\a186";
}
.fa-dots:before {
	content: "\a187";
}
.fa-add-field:before {
	content: "\a188";
}
.fa-add-plus:before {
	content: "\a189";
}
.fa-avatar:before {
	content: "\a190";
}
.fa-back-arrow:before {
	content: "\a191";
}
.fa-close:before {
	content: "\a192";
}
.fa-close-1:before {
	content: "\a193";
}
.fa-copy-icon:before {
	content: "\a194";
}
.fa-drag:before {
	content: "\a195";
}
.fa-editprofile:before {
	content: "\a196";
}
.fa-group-by:before {
	content: "\a197";
}
.fa-integrations:before {
	content: "\a198";
}
.fa-logout:before {
	content: "\a199";
}
.fa-caret-down-solid:before {
	content: "\a200";
}
.fa-sort-down-solid:before {
	content: "\a201";
}
.fa-mpin:before {
	content: "\a202";
}
.fa-no-notifications:before {
	content: "\a203";
}
.fa-notask:before {
	content: "\a204";
}
.fa-password-lock:before {
	content: "\a205";
}
.fa-preferences:before {
	content: "\a206";
}
.fa-process:before {
	content: "\a207";
}
.fa-profile-notifications:before {
	content: "\a208";
}
.fa-profile-user:before {
	content: "\a209";
}
.fa-reassign:before {
	content: "\a210";
}
.fa-reportproblem:before {
	content: "\a211";
}
.fa-right-arrow:before {
	content: "\a212";
}
.fa-sort:before {
	content: "\a213";
}
.fa-validation:before {
	content: "\a214";
}
.fa-add-record:before {
	content: "\a215";
}
.fa-dafts:before {
	content: "\a216";
}
.fa-dashboard:before {
	content: "\a217";
}
.fa-initiated:before {
	content: "\a218";
}
.fa-manage-app:before {
	content: "\a219";
}
.fa-menu:before {
	content: "\a220";
}
.fa-participated:before {
	content: "\a221";
}
.fa-reports:before {
	content: "\a222";
}
.fa-requests:before {
	content: "\a223";
}
.fa-tasks-circle:before {
	content: "\a224";
}
.fa-tasks_old:before {
	content: "\a225";
}
.fa-solutionview:before {
	content: "\a226";
}
.fa-meeting-room-light:before {
	content: "\a227";
}
.fa-external-rdbms:before {
	content: "\a228";
}
.fa-pin-inclined:before {
	content: "\a229";
}
.fa-generate-data:before {
	content: "\a230";
}
.fa-dt-filter:before {
	content: "\a231";
}
.fa-export-settings:before {
	content: "\a400";
}
.fa-caravan-alt:before {
	content: "\e000";
}
.fa-cat-space:before {
	content: "\e001";
}
.fa-coffee-pot:before {
	content: "\e002";
}
.fa-comet:before {
	content: "\e003";
}
.fa-fan-table:before {
	content: "\e004";
}
.fa-faucet:before {
	content: "\e005";
}
.fa-faucet-drip:before {
	content: "\e006";
}
.fa-galaxy:before {
	content: "\e008";
}
.fa-garage:before {
	content: "\e009";
}
.fa-garage-car:before {
	content: "\e00a";
}
.fa-garage-open:before {
	content: "\e00b";
}
.fa-heat:before {
	content: "\e00c";
}
.fa-house-day:before {
	content: "\e00e";
}
.fa-house-leave:before {
	content: "\e00f";
}
.fa-house-night:before {
	content: "\e010";
}
.fa-house-return:before {
	content: "\e011";
}
.fa-house-signal:before {
	content: "\e012";
}
.fa-lamp-desk:before {
	content: "\e014";
}
.fa-lamp-floor:before {
	content: "\e015";
}
.fa-light-ceiling:before {
	content: "\e016";
}
.fa-light-switch:before {
	content: "\e017";
}
.fa-light-switch-off:before {
	content: "\e018";
}
.fa-microwave:before {
	content: "\e01b";
}
.fa-raygun:before {
	content: "\e025";
}
.fa-rocket-launch:before {
	content: "\e027";
}
.fa-coffin-cross:before {
	content: "\e051";
}
.fa-folder-download:before {
	content: "\e053";
}
.fa-folder-upload:before {
	content: "\e054";
}
.fa-bacteria:before {
	content: "\e059";
}
.fa-bacterium:before {
	content: "\e05a";
}
.fa-box-tissue:before {
	content: "\e05b";
}
.fa-hand-holding-medical:before {
	content: "\e05c";
}
.fa-hand-sparkles:before {
	content: "\e05d";
}
.fa-hands-wash:before {
	content: "\e05e";
}
.fa-handshake-alt-slash:before {
	content: "\e05f";
}
.fa-handshake-slash:before {
	content: "\e060";
}
.fa-head-side-cough:before {
	content: "\e061";
}
.fa-head-side-cough-slash:before {
	content: "\e062";
}
.fa-head-side-mask:before {
	content: "\e063";
}
.fa-head-side-virus:before {
	content: "\e064";
}
.fa-house-user:before {
	content: "\e065";
}
.fa-laptop-house:before {
	content: "\e066";
}
.fa-lungs-virus:before {
	content: "\e067";
}
.fa-angle-double-up:before {
	content: "\e92a";
}
.fa-drum-light:before {
	content: "\e963";
}
.fa-file-signature:before {
	content: "\e98a";
}
.fa-horse-head-light:before {
	content: "\ea12";
}
.fa-image-light:before {
	content: "\ea28";
}
.fa-inventory-light:before {
	content: "\ea2f";
}
.fa-line-columns-light:before {
	content: "\ea52";
}
.fa-location-arrow-light:before {
	content: "\ea56";
}
.fa-location-circle:before {
	content: "\ea57";
}
.fa-mailbox-light:before {
	content: "\ea63";
}
.fa-map-marker-light:before {
	content: "\ea6a";
}
.fa-mug-tea:before {
	content: "\ea94";
}
.fa-music-alt-slash-light:before {
	content: "\ea95";
}
.fa-network-wired-light:before {
	content: "\ea96";
}
.fa-neuter-light:before {
	content: "\ea97";
}
.fa-notes-medical-light:before {
	content: "\ea98";
}
.fa-object-ungroup-light:before {
	content: "\ea99";
}
.fa-oil-temp-light:before {
	content: "\ea9a";
}
.fa-otter-light:before {
	content: "\ea9b";
}
.fa-outdent-light:before {
	content: "\ea9c";
}
.fa-outlet-light:before {
	content: "\ea9d";
}
.fa-oven-light:before {
	content: "\ea9e";
}
.fa-overline-light:before {
	content: "\ea9f";
}
.fa-page-break-light:before {
	content: "\eaa0";
}
.fa-chevron-left-light:before {
	content: "\eaa1";
}
.fa-mobile-android-light:before {
	content: "\eaa2";
}
.fa-comments-alt-dollar-light:before {
	content: "\eaa3";
}
.fa-bus-alt:before {
	content: "\eaa4";
}
.fa-bars-light---f0c9:before {
	content: "\eaa5";
}
.fa-bath:before {
	content: "\eaa6";
}
.fa-user-tag:before {
	content: "\eaa7";
}
.fa-trophy-alt:before {
	content: "\eaa8";
}
.fa-file-light---f15b:before {
	content: "\eaa9";
}
.fa-grip-horizontal-light---f58d:before {
	content: "\eaaa";
}
.fa-blinds-open:before {
	content: "\eaab";
}
.fa-mailbox-light---f813:before {
	content: "\eaac";
}
.fa-glass-martini:before {
	content: "\f000";
}
.fa-music:before {
	content: "\f001";
}
.fa-search:before {
	content: "\f002";
}
.fa-heart:before {
	content: "\f004";
}
.fa-star:before {
	content: "\f005";
}
.fa-user:before {
	content: "\f007";
}
.fa-film:before {
	content: "\f008";
}
.fa-th:before {
	content: "\f00a";
}
.fa-check:before {
	content: "\f00c";
}
.fa-times:before {
	content: "\f00d";
}
.fa-search-plus:before {
	content: "\f00e";
}
.fa-search-minus:before {
	content: "\f010";
}
.fa-power-off:before {
	content: "\f011";
}
.fa-signal:before {
	content: "\f012";
}
.fa-cog:before {
	content: "\f013";
}
.fa-home:before {
	content: "\f015";
}
.fa-clock:before {
	content: "\f017";
}
.fa-road:before {
	content: "\f018";
}
.fa-download:before {
	content: "\f019";
}
.fa-inbox:before {
	content: "\f01c";
}
.fa-redo:before {
	content: "\f01e";
}
.fa-sync:before {
	content: "\f021";
}
.fa-list-alt:before {
	content: "\f022";
}
.fa-lock:before {
	content: "\f023";
}
.fa-flag:before {
	content: "\f024";
}
.fa-headphones:before {
	content: "\f025";
}
.fa-volume-up:before {
	content: "\f028";
}
.fa-qrcode:before {
	content: "\f029";
}
.fa-barcode:before {
	content: "\f02a";
}
.fa-tag:before {
	content: "\f02b";
}
.fa-book:before {
	content: "\f02d";
}
.fa-bookmark:before {
	content: "\f02e";
}
.fa-print:before {
	content: "\f02f";
}
.fa-camera:before {
	content: "\f030";
}
.fa-font:before {
	content: "\f031";
}
.fa-bold:before {
	content: "\f032";
}
.fa-italic:before {
	content: "\f033";
}
.fa-text-width:before {
	content: "\f035";
}
.fa-align-left:before {
	content: "\f036";
}
.fa-align-center:before {
	content: "\f037";
}
.fa-align-right:before {
	content: "\f038";
}
.fa-align-justify:before {
	content: "\f039";
}
.fa-list:before {
	content: "\f03a";
}
.fa-indent:before {
	content: "\f03c";
}
.fa-video:before {
	content: "\f03d";
}
.fa-image:before {
	content: "\f03e";
}
.fa-pencil:before {
	content: "\f040";
}
.fa-map-marker:before {
	content: "\f041";
}
.fa-adjust:before {
	content: "\f042";
}
.fa-tint:before {
	content: "\f043";
}
.fa-edit:before {
	content: "\f044";
}
.fa-arrows:before {
	content: "\f047";
}
.fa-fast-backward:before {
	content: "\f049";
}
.fa-backward:before {
	content: "\f04a";
}
.fa-stop:before {
	content: "\f04d";
}
.fa-forward:before {
	content: "\f04e";
}
.fa-fast-forward:before {
	content: "\f050";
}
.fa-eject:before {
	content: "\f052";
}
.fa-chevron-left:before {
	content: "\f053";
}
.fa-chevron-right:before {
	content: "\f054";
}
.fa-plus-circle:before {
	content: "\f055";
}
.fa-minus-circle:before {
	content: "\f056";
}
.fa-times-circle:before {
	content: "\f057";
}
.fa-check-circle:before {
	content: "\f058";
}
.fa-question-circle:before {
	content: "\f059";
}
.fa-info-circle:before {
	content: "\f05a";
}
.fa-crosshairs:before {
	content: "\f05b";
}
.fa-ban:before {
	content: "\f05e";
}
.fa-arrow-left:before {
	content: "\f060";
}
.fa-arrow-right:before {
	content: "\f061";
}
.fa-arrow-up:before {
	content: "\f062";
}
.fa-arrow-down:before {
	content: "\f063";
}
.fa-share:before {
	content: "\f064";
}
.fa-expand:before {
	content: "\f065";
}
.fa-compress:before {
	content: "\f066";
}
.fa-plus:before {
	content: "\f067";
}
.fa-minus:before {
	content: "\f068";
}
.fa-asterisk:before {
	content: "\f069";
}
.fa-exclamation-circle:before {
	content: "\f06a";
}
.fa-gift:before {
	content: "\f06b";
}
.fa-leaf:before {
	content: "\f06c";
}
.fa-fire:before {
	content: "\f06d";
}
.fa-eye:before {
	content: "\f06e";
}
.fa-eye-slash:before {
	content: "\f070";
}
.fa-exclamation-triangle:before {
	content: "\f071";
}
.fa-plane:before {
	content: "\f072";
}
.fa-calendar-alt:before {
	content: "\f073";
}
.fa-random:before {
	content: "\f074";
}
.fa-comment:before {
	content: "\f075";
}
.fa-magnet:before {
	content: "\f076";
}
.fa-chevron-up:before {
	content: "\f077";
}
.fa-chevron-down:before {
	content: "\f078";
}
.fa-shopping-cart:before {
	content: "\f07a";
}
.fa-folder:before {
	content: "\f07b";
}
.fa-folder-open:before {
	content: "\f07c";
}
.fa-arrows-v:before {
	content: "\f07d";
}
.fa-arrows-h:before {
	content: "\f07e";
}
.fa-chart-bar:before {
	content: "\f080";
}
.fa-camera-retro:before {
	content: "\f083";
}
.fa-key:before {
	content: "\f084";
}
.fa-cogs:before {
	content: "\f085";
}
.fa-comments:before {
	content: "\f086";
}
.fa-sign-out:before {
	content: "\f08b";
}
.fa-thumbtack:before {
	content: "\f08d";
}
.fa-external-link:before {
	content: "\f08e";
}
.fa-upload:before {
	content: "\f093";
}
.fa-lemon:before {
	content: "\f094";
}
.fa-phone-square:before {
	content: "\f098";
}
.fa-credit-card:before {
	content: "\f09d";
}
.fa-rss:before {
	content: "\f09e";
}
.fa-hdd:before {
	content: "\f0a0";
}
.fa-bullhorn:before {
	content: "\f0a1";
}
.fa-certificate:before {
	content: "\f0a3";
}
.fa-hand-point-right:before {
	content: "\f0a4";
}
.fa-hand-point-left:before {
	content: "\f0a5";
}
.fa-hand-point-up:before {
	content: "\f0a6";
}
.fa-hand-point-down:before {
	content: "\f0a7";
}
.fa-arrow-circle-left:before {
	content: "\f0a8";
}
.fa-arrow-circle-right:before {
	content: "\f0a9";
}
.fa-arrow-circle-up:before {
	content: "\f0aa";
}
.fa-arrow-circle-down:before {
	content: "\f0ab";
}
.fa-globe:before {
	content: "\f0ac";
}
.fa-wrench:before {
	content: "\f0ad";
}
.fa-tasks:before {
	content: "\f0ae";
}
.fa-filter:before {
	content: "\f0b0";
}
.fa-briefcase:before {
	content: "\f0b1";
}
.fa-arrows-alt:before {
	content: "\f0b2";
}
.fa-users:before {
	content: "\f0c0";
}
.fa-link:before {
	content: "\f0c1";
}
.fa-cloud:before {
	content: "\f0c2";
}
.fa-flask:before {
	content: "\f0c3";
}
.fa-cut:before {
	content: "\f0c4";
}
.fa-copy:before {
	content: "\f0c5";
}
.fa-paperclip:before {
	content: "\f0c6";
}
.fa-save:before {
	content: "\f0c7";
}
.fa-square:before {
	content: "\f0c8";
}
.fa-bars:before {
	content: "\f0c9";
}
.fa-list-ul:before {
	content: "\f0ca";
}
.fa-list-ol:before {
	content: "\f0cb";
}
.fa-table:before {
	content: "\f0ce";
}
.fa-magic:before {
	content: "\f0d0";
}
.fa-truck:before {
	content: "\f0d1";
}
.fa-money-bill:before {
	content: "\f0d6";
}
.fa-caret-down:before {
	content: "\f0d7";
}
.fa-caret-up:before {
	content: "\f0d8";
}
.fa-caret-left:before {
	content: "\f0d9";
}
.fa-caret-right:before {
	content: "\f0da";
}
.fa-columns:before {
	content: "\f0db";
}
.fa-sort-down:before {
	content: "\f0dd";
}
.fa-envelope:before {
	content: "\f0e0";
}
.fa-undo:before {
	content: "\f0e2";
}
.fa-gavel:before {
	content: "\f0e3";
}
.fa-tachometer:before {
	content: "\f0e4";
}
.fa-bolt:before {
	content: "\f0e7";
}
.fa-sitemap:before {
	content: "\f0e8";
}
.fa-umbrella:before {
	content: "\f0e9";
}
.fa-paste:before {
	content: "\f0ea";
}
.fa-lightbulb:before {
	content: "\f0eb";
}
.fa-exchange:before {
	content: "\f0ec";
}
.fa-cloud-download:before {
	content: "\f0ed";
}
.fa-cloud-upload:before {
	content: "\f0ee";
}
.fa-user-md:before {
	content: "\f0f0";
}
.fa-stethoscope:before {
	content: "\f0f1";
}
.fa-bell:before {
	content: "\f0f3";
}
.fa-coffee:before {
	content: "\f0f4";
}
.fa-hospital:before {
	content: "\f0f8";
}
.fa-ambulance:before {
	content: "\f0f9";
}
.fa-medkit:before {
	content: "\f0fa";
}
.fa-fighter-jet:before {
	content: "\f0fb";
}
.fa-beer:before {
	content: "\f0fc";
}
.fa-h-square:before {
	content: "\f0fd";
}
.fa-plus-square:before {
	content: "\f0fe";
}
.fa-angle-double-left:before {
	content: "\f100";
}
.fa-angle-double-right:before {
	content: "\f101";
}
.fa-angle-double-down:before {
	content: "\f103";
}
.fa-angle-left:before {
	content: "\f104";
}
.fa-angle-right:before {
	content: "\f105";
}
.fa-angle-up:before {
	content: "\f106";
}
.fa-angle-down:before {
	content: "\f107";
}
.fa-desktop:before {
	content: "\f108";
}
.fa-laptop:before {
	content: "\f109";
}
.fa-mobile:before {
	content: "\f10b";
}
.fa-quote-left:before {
	content: "\f10d";
}
.fa-quote-right:before {
	content: "\f10e";
}
.fa-spinner:before {
	content: "\f110";
}
.fa-circle:before {
	content: "\f111";
}
.fa-smile:before {
	content: "\f118";
}
.fa-frown:before {
	content: "\f119";
}
.fa-meh:before {
	content: "\f11a";
}
.fa-gamepad:before {
	content: "\f11b";
}
.fa-keyboard:before {
	content: "\f11c";
}
.fa-flag-checkered:before {
	content: "\f11e";
}
.fa-terminal:before {
	content: "\f120";
}
.fa-code:before {
	content: "\f121";
}
.fa-location-arrow:before {
	content: "\f124";
}
.fa-crop:before {
	content: "\f125";
}
.fa-code-branch:before {
	content: "\f126";
}
.fa-info:before {
	content: "\f129";
}
.fa-exclamation:before {
	content: "\f12a";
}
.fa-eraser:before {
	content: "\f12d";
}
.fa-puzzle-piece:before {
	content: "\f12e";
}
.fa-microphone:before {
	content: "\f130";
}
.fa-microphone-slash:before {
	content: "\f131";
}
.fa-shield:before {
	content: "\f132";
}
.fa-calendar:before {
	content: "\f133";
}
.fa-fire-extinguisher:before {
	content: "\f134";
}
.fa-rocket:before {
	content: "\f135";
}
.fa-chevron-circle-left:before {
	content: "\f137";
}
.fa-chevron-circle-right:before {
	content: "\f138";
}
.fa-chevron-circle-up:before {
	content: "\f139";
}
.fa-chevron-circle-down:before {
	content: "\f13a";
}
.fa-css3:before {
	content: "\f13c";
}
.fa-anchor:before {
	content: "\f13d";
}
.fa-unlock-alt:before {
	content: "\f13e";
}
.fa-bullseye:before {
	content: "\f140";
}
.fa-ellipsis-h:before {
	content: "\f141";
}
.fa-ellipsis-v:before {
	content: "\f142";
}
.fa-play-circle:before {
	content: "\f144";
}
.fa-ticket:before {
	content: "\f145";
}
.fa-minus-square:before {
	content: "\f146";
}
.fa-level-up:before {
	content: "\f148";
}
.fa-level-down:before {
	content: "\f149";
}
.fa-check-square:before {
	content: "\f14a";
}
.fa-external-link-square:before {
	content: "\f14c";
}
.fa-share-square:before {
	content: "\f14d";
}
.fa-compass:before {
	content: "\f14e";
}
.fa-caret-square-down:before {
	content: "\f150";
}
.fa-caret-square-up:before {
	content: "\f151";
}
.fa-caret-square-right:before {
	content: "\f152";
}
.fa-euro-sign:before {
	content: "\f153";
}
.fa-pound-sign:before {
	content: "\f154";
}
.fa-dollar-sign:before {
	content: "\f155";
}
.fa-rupee-sign:before {
	content: "\f156";
}
.fa-yen-sign:before {
	content: "\f157";
}
.fa-ruble-sign:before {
	content: "\f158";
}
.fa-file:before {
	content: "\f15b";
}
.fa-file-alt:before {
	content: "\f15c";
}
.fa-sort-numeric-down:before {
	content: "\f162";
}
.fa-thumbs-up:before {
	content: "\f164";
}
.fa-thumbs-down:before {
	content: "\f165";
}
.fa-adn:before {
	content: "\f170";
}
.fa-bitbucket:before {
	content: "\f171";
}
.fa-long-arrow-down:before {
	content: "\f175";
}
.fa-long-arrow-up:before {
	content: "\f176";
}
.fa-long-arrow-left:before {
	content: "\f177";
}
.fa-long-arrow-right:before {
	content: "\f178";
}
.fa-android:before {
	content: "\f17b";
}
.fa-female:before {
	content: "\f182";
}
.fa-male:before {
	content: "\f183";
}
.fa-sun:before {
	content: "\f185";
}
.fa-moon:before {
	content: "\f186";
}
.fa-archive:before {
	content: "\f187";
}
.fa-bug:before {
	content: "\f188";
}
.fa-pagelines:before {
	content: "\f18c";
}
.fa-caret-square-left:before {
	content: "\f191";
}
.fa-dot-circle:before {
	content: "\f192";
}
.fa-wheelchair:before {
	content: "\f193";
}
.fa-lira-sign:before {
	content: "\f195";
}
.fa-space-shuttle:before {
	content: "\f197";
}
.fa-envelope-square:before {
	content: "\f199";
}
.fa-openid:before {
	content: "\f19b";
}
.fa-university:before {
	content: "\f19c";
}
.fa-graduation-cap:before {
	content: "\f19d";
}
.fa-google:before {
	content: "\f1a0";
}
.fa-stumbleupon:before {
	content: "\f1a4";
}
.fa-drupal:before {
	content: "\f1a9";
}
.fa-language:before {
	content: "\f1ab";
}
.fa-fax:before {
	content: "\f1ac";
}
.fa-building:before {
	content: "\f1ad";
}
.fa-child:before {
	content: "\f1ae";
}
.fa-paw:before {
	content: "\f1b0";
}
.fa-cube:before {
	content: "\f1b2";
}
.fa-cubes:before {
	content: "\f1b3";
}
.fa-behance:before {
	content: "\f1b4";
}
.fa-behance-square:before {
	content: "\f1b5";
}
.fa-recycle:before {
	content: "\f1b8";
}
.fa-car:before {
	content: "\f1b9";
}
.fa-taxi:before {
	content: "\f1ba";
}
.fa-tree:before {
	content: "\f1bb";
}
.fa-deviantart:before {
	content: "\f1bd";
}
.fa-database:before {
	content: "\f1c0";
}
.fa-file-pdf:before {
	content: "\f1c1";
}
.fa-file-word:before {
	content: "\f1c2";
}
.fa-file-excel:before {
	content: "\f1c3";
}
.fa-file-powerpoint:before {
	content: "\f1c4";
}
.fa-file-image:before {
	content: "\f1c5";
}
.fa-file-archive:before {
	content: "\f1c6";
}
.fa-file-audio:before {
	content: "\f1c7";
}
.fa-file-video:before {
	content: "\f1c8";
}
.fa-file-code:before {
	content: "\f1c9";
}
.fa-vine:before {
	content: "\f1ca";
}
.fa-codepen:before {
	content: "\f1cb";
}
.fa-life-ring:before {
	content: "\f1cd";
}
.fa-circle-notch:before {
	content: "\f1ce";
}
.fa-rebel:before {
	content: "\f1d0";
}
.fa-qq:before {
	content: "\f1d6";
}
.fa-paper-plane:before {
	content: "\f1d8";
}
.fa-history:before {
	content: "\f1da";
}
.fa-heading:before {
	content: "\f1dc";
}
.fa-paragraph:before {
	content: "\f1dd";
}
.fa-share-alt:before {
	content: "\f1e0";
}
.fa-bomb:before {
	content: "\f1e2";
}
.fa-futbol:before {
	content: "\f1e3";
}
.fa-binoculars:before {
	content: "\f1e5";
}
.fa-plug:before {
	content: "\f1e6";
}
.fa-newspapers:before {
	content: "\f1ea";
}
.fa-wifi:before {
	content: "\f1eb";
}
.fa-calculator:before {
	content: "\f1ec";
}
.fa-cc-visa:before {
	content: "\f1f0";
}
.fa-cc-mastercard:before {
	content: "\f1f1";
}
.fa-cc-discover:before {
	content: "\f1f2";
}
.fa-cc-amex:before {
	content: "\f1f3";
}
.fa-cc-paypal:before {
	content: "\f1f4";
}
.fa-cc-stripe:before {
	content: "\f1f5";
}
.fa-bell-slash:before {
	content: "\f1f6";
}
.fa-trash:before {
	content: "\f1f8";
}
.fa-copyright:before {
	content: "\f1f9";
}
.fa-at:before {
	content: "\f1fa";
}
.fa-eye-dropper:before {
	content: "\f1fb";
}
.fa-paint-brush:before {
	content: "\f1fc";
}
.fa-birthday-cake:before {
	content: "\f1fd";
}
.fa-chart-area:before {
	content: "\f1fe";
}
.fa-chart-pie:before {
	content: "\f200";
}
.fa-chart-line:before {
	content: "\f201";
}
.fa-toggle-off:before {
	content: "\f204";
}
.fa-toggle-on:before {
	content: "\f205";
}
.fa-bicycle:before {
	content: "\f206";
}
.fa-bus:before {
	content: "\f207";
}
.fa-angellist:before {
	content: "\f209";
}
.fa-closed-captioning:before {
	content: "\f20a";
}
.fa-buysellads:before {
	content: "\f20d";
}
.fa-connectdevelop:before {
	content: "\f20e";
}
.fa-dashcube:before {
	content: "\f210";
}
.fa-cart-plus:before {
	content: "\f217";
}
.fa-cart-arrow-down:before {
	content: "\f218";
}
.fa-diamond:before {
	content: "\f219";
}
.fa-ship:before {
	content: "\f21a";
}
.fa-motorcycle:before {
	content: "\f21c";
}
.fa-heartbeat:before {
	content: "\f21e";
}
.fa-mars:before {
	content: "\f222";
}
.fa-mercury:before {
	content: "\f223";
}
.fa-mars-double:before {
	content: "\f227";
}
.fa-mars-stroke:before {
	content: "\f229";
}
.fa-mars-stroke-v:before {
	content: "\f22a";
}
.fa-mars-stroke-h:before {
	content: "\f22b";
}
.fa-genderless:before {
	content: "\f22d";
}
.fa-whatsapp:before {
	content: "\f232";
}
.fa-server:before {
	content: "\f233";
}
.fa-user-plus:before {
	content: "\f234";
}
.fa-user-times:before {
	content: "\f235";
}
.fa-bed:before {
	content: "\f236";
}
.fa-train:before {
	content: "\f238";
}
.fa-battery-full:before {
	content: "\f240";
}
.fa-battery-three-quarters:before {
	content: "\f241";
}
.fa-battery-half:before {
	content: "\f242";
}
.fa-battery-quarter:before {
	content: "\f243";
}
.fa-battery-empty:before {
	content: "\f244";
}
.fa-mouse-pointer:before {
	content: "\f245";
}
.fa-i-cursor:before {
	content: "\f246";
}
.fa-object-group:before {
	content: "\f247";
}
.fa-sticky-note:before {
	content: "\f249";
}
.fa-cc-jcb:before {
	content: "\f24b";
}
.fa-cc-diners-club:before {
	content: "\f24c";
}
.fa-clone:before {
	content: "\f24d";
}
.fa-balance-scale:before {
	content: "\f24e";
}
.fa-hourglass-start:before {
	content: "\f251";
}
.fa-hourglass-half:before {
	content: "\f252";
}
.fa-hourglass-end:before {
	content: "\f253";
}
.fa-hourglass:before {
	content: "\f254";
}
.fa-hand-rock:before {
	content: "\f255";
}
.fa-hand-paper:before {
	content: "\f256";
}
.fa-hand-scissors:before {
	content: "\f257";
}
.fa-hand-lizard:before {
	content: "\f258";
}
.fa-hand-spock:before {
	content: "\f259";
}
.fa-hand-pointer:before {
	content: "\f25a";
}
.fa-hand-peace:before {
	content: "\f25b";
}
.fa-trademark:before {
	content: "\f25c";
}
.fa-registered:before {
	content: "\f25d";
}
.fa-creative-commons:before {
	content: "\f25e";
}
.fa-gg-circle:before {
	content: "\f261";
}
.fa-chrome:before {
	content: "\f268";
}
.fa-tv:before {
	content: "\f26c";
}
.fa-contao:before {
	content: "\f26d";
}
.fa-500px:before {
	content: "\f26e";
}
.fa-amazon:before {
	content: "\f270";
}
.fa-calendar-plus:before {
	content: "\f271";
}
.fa-calendar-minus:before {
	content: "\f272";
}
.fa-calendar-times:before {
	content: "\f273";
}
.fa-calendar-check:before {
	content: "\f274";
}
.fa-industry:before {
	content: "\f275";
}
.fa-map-pin:before {
	content: "\f276";
}
.fa-map-signs:before {
	content: "\f277";
}
.fa-comment-alt:before {
	content: "\f27a";
}
.fa-black-tie:before {
	content: "\f27e";
}
.fa-codiepie:before {
	content: "\f284";
}
.fa-pause-circle:before {
	content: "\f28b";
}
.fa-stop-circle:before {
	content: "\f28d";
}
.fa-hashtag:before {
	content: "\f292";
}
.fa-bluetooth:before {
	content: "\f293";
}
.fa-bluetooth-b:before {
	content: "\f294";
}
.fa-universal-access:before {
	content: "\f29a";
}
.fa-blind:before {
	content: "\f29d";
}
.fa-audio-description:before {
	content: "\f29e";
}
.fa-braille:before {
	content: "\f2a1";
}
.fa-assistive-listening-systems:before {
	content: "\f2a2";
}
.fa-american-sign-language-interpreting:before {
	content: "\f2a3";
}
.fa-deaf:before {
	content: "\f2a4";
}
.fa-low-vision:before {
	content: "\f2a8";
}
.fa-handshake:before {
	content: "\f2b5";
}
.fa-envelope-open:before {
	content: "\f2b6";
}
.fa-address-book:before {
	content: "\f2b9";
}
.fa-address-card:before {
	content: "\f2bb";
}
.fa-user-circle:before {
	content: "\f2bd";
}
.fa-id-badge:before {
	content: "\f2c1";
}
.fa-id-card:before {
	content: "\f2c2";
}
.fa-thermometer-full:before {
	content: "\f2c7";
}
.fa-shower:before {
	content: "\f2cc";
}
.fa-podcast:before {
	content: "\f2ce";
}
.fa-window-restore:before {
	content: "\f2d2";
}
.fa-microchip:before {
	content: "\f2db";
}
.fa-snowflake:before {
	content: "\f2dc";
}
.fa-watch:before {
	content: "\f2e1";
}
.fa-utensils-alt:before {
	content: "\f2e6";
}
.fa-trophy:before {
	content: "\f2eb";
}
.fa-triangle:before {
	content: "\f2ec";
}
.fa-trash-alt:before {
	content: "\f2ed";
}
.fa-sync-alt:before {
	content: "\f2f1";
}
.fa-stopwatch:before {
	content: "\f2f2";
}
.fa-spade:before {
	content: "\f2f4";
}
.fa-sign-out-alt:before {
	content: "\f2f5";
}
.fa-sign-in-alt:before {
	content: "\f2f6";
}
.fa-uniF2F7:before {
	content: "\f2f7";
}
.fa-uniF2F8:before {
	content: "\f2f8";
}
.fa-rectangle-landscape:before {
	content: "\f2fa";
}
.fa-rectangle-portrait:before {
	content: "\f2fb";
}
.fa-poo:before {
	content: "\f2fe";
}
.fa-images:before {
	content: "\f302";
}
.fa-pencil-alt-light:before {
	content: "\f303";
}
.fa-octagon:before {
	content: "\f306";
}
.fa-minus-hexagon:before {
	content: "\f307";
}
.fa-minus-octagon:before {
	content: "\f308";
}
.fa-long-arrow-alt-down:before {
	content: "\f309";
}
.fa-long-arrow-alt-left:before {
	content: "\f30a";
}
.fa-long-arrow-alt-right:before {
	content: "\f30b";
}
.fa-long-arrow-alt-up:before {
	content: "\f30c";
}
.fa-lock-alt:before {
	content: "\f30d";
}
.fa-jack-o-lantern:before {
	content: "\f30e";
}
.fa-info-square:before {
	content: "\f30f";
}
.fa-inbox-in:before {
	content: "\f310";
}
.fa-inbox-out:before {
	content: "\f311";
}
.fa-hexagon:before {
	content: "\f312";
}
.fa-h1:before {
	content: "\f313";
}
.fa-h2:before {
	content: "\f314";
}
.fa-h3:before {
	content: "\f315";
}
.fa-file-check:before {
	content: "\f316";
}
.fa-file-times:before {
	content: "\f317";
}
.fa-file-minus:before {
	content: "\f318";
}
.fa-file-plus:before {
	content: "\f319";
}
.fa-file-exclamation:before {
	content: "\f31a";
}
.fa-file-edit:before {
	content: "\f31c";
}
.fa-expand-arrows:before {
	content: "\f31d";
}
.fa-expand-arrows-alt:before {
	content: "\f31e";
}
.fa-expand-wide:before {
	content: "\f320";
}
.fa-exclamation-square:before {
	content: "\f321";
}
.fa-chevron-double-down:before {
	content: "\f322";
}
.fa-chevron-double-left:before {
	content: "\f323";
}
.fa-chevron-double-right:before {
	content: "\f324";
}
.fa-chevron-double-up:before {
	content: "\f325";
}
.fa-compress-wide:before {
	content: "\f326";
}
.fa-club:before {
	content: "\f327";
}
.fa-clipboard:before {
	content: "\f328";
}
.fa-chevron-square-down:before {
	content: "\f329";
}
.fa-chevron-square-left:before {
	content: "\f32a";
}
.fa-chevron-square-right:before {
	content: "\f32b";
}
.fa-chevron-square-up:before {
	content: "\f32c";
}
.fa-caret-circle-down:before {
	content: "\f32d";
}
.fa-caret-circle-left:before {
	content: "\f32e";
}
.fa-caret-circle-right:before {
	content: "\f330";
}
.fa-caret-circle-up:before {
	content: "\f331";
}
.fa-camera-alt:before {
	content: "\f332";
}
.fa-calendar-exclamation:before {
	content: "\f334";
}
.fa-badge:before {
	content: "\f335";
}
.fa-badge-check:before {
	content: "\f336";
}
.fa-arrows-alt-h:before {
	content: "\f337";
}
.fa-arrows-alt-v:before {
	content: "\f338";
}
.fa-arrow-square-down:before {
	content: "\f339";
}
.fa-arrow-square-left:before {
	content: "\f33a";
}
.fa-arrow-square-right:before {
	content: "\f33b";
}
.fa-arrow-square-up:before {
	content: "\f33c";
}
.fa-arrow-to-bottom:before {
	content: "\f33d";
}
.fa-arrow-to-left:before {
	content: "\f33e";
}
.fa-arrow-to-right:before {
	content: "\f340";
}
.fa-arrow-to-top:before {
	content: "\f341";
}
.fa-arrow-from-bottom:before {
	content: "\f342";
}
.fa-arrow-from-left:before {
	content: "\f343";
}
.fa-arrow-from-right:before {
	content: "\f344";
}
.fa-arrow-from-top:before {
	content: "\f345";
}
.fa-arrow-alt-from-bottom:before {
	content: "\f346";
}
.fa-arrow-alt-from-left:before {
	content: "\f347";
}
.fa-arrow-alt-from-right:before {
	content: "\f348";
}
.fa-arrow-alt-from-top:before {
	content: "\f349";
}
.fa-arrow-alt-to-bottom:before {
	content: "\f34a";
}
.fa-arrow-alt-to-left:before {
	content: "\f34b";
}
.fa-arrow-alt-to-right:before {
	content: "\f34c";
}
.fa-arrow-alt-to-top:before {
	content: "\f34d";
}
.fa-alarm-clock:before {
	content: "\f34e";
}
.fa-arrow-alt-square-down:before {
	content: "\f350";
}
.fa-arrow-alt-square-left:before {
	content: "\f351";
}
.fa-arrow-alt-square-right:before {
	content: "\f352";
}
.fa-arrow-alt-square-up-:before {
	content: "\f353";
}
.fa-arrow-alt-down:before {
	content: "\f354";
}
.fa-arrow-alt-left:before {
	content: "\f355";
}
.fa-arrow-alt-right:before {
	content: "\f356";
}
.fa-arrow-alt-up:before {
	content: "\f357";
}
.fa-arrow-alt-circle-down:before {
	content: "\f358";
}
.fa-arrow-alt-circle-left:before {
	content: "\f359";
}
.fa-arrow-alt-circle-right:before {
	content: "\f35a";
}
.fa-arrow-alt-circle-up:before {
	content: "\f35b";
}
.fa-external-link-alt:before {
	content: "\f35d";
}
.fa-external-link-square-alt:before {
	content: "\f360";
}
.fa-exchange-alt:before {
	content: "\f362";
}
.fa-repeat:before {
	content: "\f363";
}
.fa-accessible-icon:before {
	content: "\f368";
}
.fa-accusoft:before {
	content: "\f369";
}
.fa-adversalbrands:before {
	content: "\f36a";
}
.fa-affiliatetheme:before {
	content: "\f36b";
}
.fa-algolia:before {
	content: "\f36c";
}
.fa-amilia:before {
	content: "\f36d";
}
.fa-app-store:before {
	content: "\f36f";
}
.fa-app-store-ios:before {
	content: "\f370";
}
.fa-asymmetrik:before {
	content: "\f372";
}
.fa-avianex:before {
	content: "\f374";
}
.fa-aws:before {
	content: "\f375";
}
.fa-battery-bolt:before {
	content: "\f376";
}
.fa-battery-slash:before {
	content: "\f377";
}
.fa-bitcoin:before {
	content: "\f379";
}
.fa-bity:before {
	content: "\f37a";
}
.fa-blackberry:before {
	content: "\f37b";
}
.fa-blogger:before {
	content: "\f37c";
}
.fa-blogger-b:before {
	content: "\f37d";
}
.fa-browser:before {
	content: "\f37e";
}
.fa-buromobelexperte:before {
	content: "\f37f";
}
.fa-centercode:before {
	content: "\f380";
}
.fa-cloud-download-alt:before {
	content: "\f381";
}
.fa-cloud-upload-alt:before {
	content: "\f382";
}
.fa-cloudscale:before {
	content: "\f383";
}
.fa-cloudsmith:before {
	content: "\f384";
}
.fa-cloudversify:before {
	content: "\f385";
}
.fa-code-commit:before {
	content: "\f386";
}
.fa-code-merge:before {
	content: "\f387";
}
.fa-cpanel:before {
	content: "\f388";
}
.fa-credit-card-blank:before {
	content: "\f389";
}
.fa-credit-card-front:before {
	content: "\f38a";
}
.fa-css3-alt:before {
	content: "\f38b";
}
.fa-cuttlefish:before {
	content: "\f38c";
}
.fa-d-and-d:before {
	content: "\f38d";
}
.fa-deskpro:before {
	content: "\f38f";
}
.fa-desktop-alt:before {
	content: "\f390";
}
.fa-ellipsis-v-alt:before {
	content: "\f39c";
}
.fa-film-alt:before {
	content: "\f3a0";
}
.fa-gem:before {
	content: "\f3a5";
}
.fa-industry-alt:before {
	content: "\f3b3";
}
.fa-level-down-alt:before {
	content: "\f3be";
}
.fa-level-up-alt:before {
	content: "\f3bf";
}
.fa-lock-open:before {
	content: "\f3c1";
}
.fa-lock-open-alt:before {
	content: "\f3c2";
}
.fa-map-marker-alt:before {
	content: "\f3c5";
}
.fa-microphone-alt:before {
	content: "\f3c9";
}
.fa-mobile-alt:before {
	content: "\f3cd";
}
.fa-mobile-android:before {
	content: "\f3ce";
}
.fa-mobile-android-alt:before {
	content: "\f3cf";
}
.fa-money-bill-alt:before {
	content: "\f3d1";
}
.fa-portrait:before {
	content: "\f3e0";
}
.fa-reply:before {
	content: "\f3e5";
}
.fa-sliders-v:before {
	content: "\f3f1";
}
.fa-sliders-v-square:before {
	content: "\f3f2";
}
.fa-user-alt:before {
	content: "\f406";
}
.fa-window-alt:before {
	content: "\f40f";
}
.fa-apple-pay:before {
	content: "\f415";
}
.fa-cc-apple-pay:before {
	content: "\f416";
}
.fa-autoprefixer:before {
	content: "\f41c";
}
.fa-angular:before {
	content: "\f420";
}
.fa-compress-alt:before {
	content: "\f422";
}
.fa-expand-alt:before {
	content: "\f424";
}
.fa-amazon-pay:before {
	content: "\f42c";
}
.fa-cc-amazon-pay:before {
	content: "\f42d";
}
.fa-baseball:before {
	content: "\f432";
}
.fa-baseball-ball:before {
	content: "\f433";
}
.fa-basketball-ball:before {
	content: "\f434";
}
.fa-basketball-hoop:before {
	content: "\f435";
}
.fa-bowling-ball:before {
	content: "\f436";
}
.fa-bowling-pins:before {
	content: "\f437";
}
.fa-boxing-glove:before {
	content: "\f438";
}
.fa-chess:before {
	content: "\f439";
}
.fa-chess-bishop:before {
	content: "\f43a";
}
.fa-chess-bishop-alt:before {
	content: "\f43b";
}
.fa-chess-board:before {
	content: "\f43c";
}
.fa-chess-clock:before {
	content: "\f43d";
}
.fa-chess-clock-alt:before {
	content: "\f43e";
}
.fa-chess-king:before {
	content: "\f43f";
}
.fa-chess-king-alt:before {
	content: "\f440";
}
.fa-chess-knight:before {
	content: "\f441";
}
.fa-chess-knight-alt:before {
	content: "\f442";
}
.fa-chess-pawn:before {
	content: "\f443";
}
.fa-chess-pawn-alt:before {
	content: "\f444";
}
.fa-chess-queen:before {
	content: "\f445";
}
.fa-chess-queen-alt:before {
	content: "\f446";
}
.fa-chess-rook:before {
	content: "\f447";
}
.fa-chess-rook-alt:before {
	content: "\f448";
}
.fa-cricket:before {
	content: "\f449";
}
.fa-curling:before {
	content: "\f44a";
}
.fa-dumbbell:before {
	content: "\f44b";
}
.fa-field-hockey:before {
	content: "\f44c";
}
.fa-football-ball:before {
	content: "\f44e";
}
.fa-football-helmet:before {
	content: "\f44f";
}
.fa-golf-ball:before {
	content: "\f450";
}
.fa-golf-club:before {
	content: "\f451";
}
.fa-hockey-puck:before {
	content: "\f453";
}
.fa-hockey-sticks:before {
	content: "\f454";
}
.fa-luchador:before {
	content: "\f455";
}
.fa-racquet:before {
	content: "\f45a";
}
.fa-shuttlecock:before {
	content: "\f45b";
}
.fa-square-full:before {
	content: "\f45c";
}
.fa-table-tennis:before {
	content: "\f45d";
}
.fa-tennis-ball:before {
	content: "\f45e";
}
.fa-whistle:before {
	content: "\f460";
}
.fa-allergies:before {
	content: "\f461";
}
.fa-band-aid:before {
	content: "\f462";
}
.fa-barcode-alt:before {
	content: "\f463";
}
.fa-barcode-read:before {
	content: "\f464";
}
.fa-barcode-scan:before {
	content: "\f465";
}
.fa-box:before {
	content: "\f466";
}
.fa-box-check:before {
	content: "\f467";
}
.fa-boxes:before {
	content: "\f468";
}
.fa-briefcase-medical:before {
	content: "\f469";
}
.fa-burn:before {
	content: "\f46a";
}
.fa-capsules:before {
	content: "\f46b";
}
.fa-clipboard-check:before {
	content: "\f46c";
}
.fa-clipboard-list:before {
	content: "\f46d";
}
.fa-conveyor-belt:before {
	content: "\f46e";
}
.fa-conveyor-belt-alt:before {
	content: "\f46f";
}
.fa-diagnoses:before {
	content: "\f470";
}
.fa-dna:before {
	content: "\f471";
}
.fa-dolly:before {
	content: "\f472";
}
.fa-dolly-empty:before {
	content: "\f473";
}
.fa-dolly-flatbed:before {
	content: "\f474";
}
.fa-dolly-flatbed-alt:before {
	content: "\f475";
}
.fa-dolly-flatbed-empty:before {
	content: "\f476";
}
.fa-file-medical:before {
	content: "\f477";
}
.fa-file-medical-alt:before {
	content: "\f478";
}
.fa-first-aid:before {
	content: "\f479";
}
.fa-forklift:before {
	content: "\f47a";
}
.fa-hand-holding-box:before {
	content: "\f47b";
}
.fa-hand-receiving:before {
	content: "\f47c";
}
.fa-hospital-alt:before {
	content: "\f47d";
}
.fa-hospital-symbol:before {
	content: "\f47e";
}
.fa-id-card-alt:before {
	content: "\f47f";
}
.fa-inventory:before {
	content: "\f480";
}
.fa-pills:before {
	content: "\f484";
}
.fa-smoking:before {
	content: "\f48d";
}
.fa-syringe:before {
	content: "\f48e";
}
.fa-tablets:before {
	content: "\f490";
}
.fa-warehouse:before {
	content: "\f494";
}
.fa-weight:before {
	content: "\f496";
}
.fa-x-ray:before {
	content: "\f497";
}
.fa-blanket:before {
	content: "\f498";
}
.fa-book-heart:before {
	content: "\f499";
}
.fa-box-alt:before {
	content: "\f49a";
}
.fa-box-fragile:before {
	content: "\f49b";
}
.fa-box-full:before {
	content: "\f49c";
}
.fa-box-heart:before {
	content: "\f49d";
}
.fa-box-open:before {
	content: "\f49e";
}
.fa-box-up:before {
	content: "\f49f";
}
.fa-box-usd:before {
	content: "\f4a0";
}
.fa-boxes-alt:before {
	content: "\f4a1";
}
.fa-comment-alt-check:before {
	content: "\f4a2";
}
.fa-comment-alt-dots:before {
	content: "\f4a3";
}
.fa-comment-alt-edit:before {
	content: "\f4a4";
}
.fa-comment-alt-exclamation:before {
	content: "\f4a5";
}
.fa-comment-alt-lines:before {
	content: "\f4a6";
}
.fa-comment-alt-minus:before {
	content: "\f4a7";
}
.fa-comment-alt-plus:before {
	content: "\f4a8";
}
.fa-comment-alt-smile:before {
	content: "\f4aa";
}
.fa-comment-alt-times:before {
	content: "\f4ab";
}
.fa-comment-check:before {
	content: "\f4ac";
}
.fa-comment-dots:before {
	content: "\f4ad";
}
.fa-comment-edit:before {
	content: "\f4ae";
}
.fa-comment-exclamation:before {
	content: "\f4af";
}
.fa-comment-lines:before {
	content: "\f4b0";
}
.fa-comment-minus:before {
	content: "\f4b1";
}
.fa-comment-plus:before {
	content: "\f4b2";
}
.fa-comment-slash:before {
	content: "\f4b3";
}
.fa-comment-smile:before {
	content: "\f4b4";
}
.fa-comment-times:before {
	content: "\f4b5";
}
.fa-comments-alt:before {
	content: "\f4b6";
}
.fa-container-storage:before {
	content: "\f4b7";
}
.fa-couch:before {
	content: "\f4b8";
}
.fa-donate:before {
	content: "\f4b9";
}
.fa-dove:before {
	content: "\f4ba";
}
.fa-fragile:before {
	content: "\f4bb";
}
.fa-hand-heart:before {
	content: "\f4bc";
}
.fa-hand-holding:before {
	content: "\f4bd";
}
.fa-hand-holding-heart:before {
	content: "\f4be";
}
.fa-hand-holding-seedling:before {
	content: "\f4bf";
}
.fa-hand-holding-usd:before {
	content: "\f4c0";
}
.fa-hand-holding-water:before {
	content: "\f4c1";
}
.fa-hands:before {
	content: "\f4c2";
}
.fa-hands-heart:before {
	content: "\f4c3";
}
.fa-hands-helping:before {
	content: "\f4c4";
}
.fa-hands-usd:before {
	content: "\f4c5";
}
.fa-handshake-alt:before {
	content: "\f4c6";
}
.fa-heart-circle:before {
	content: "\f4c7";
}
.fa-heart-square:before {
	content: "\f4c8";
}
.fa-home-heart:before {
	content: "\f4c9";
}
.fa-lamp:before {
	content: "\f4ca";
}
.fa-leaf-heart:before {
	content: "\f4cb";
}
.fa-parachute-box:before {
	content: "\f4cd";
}
.fa-piggy-bank:before {
	content: "\f4d3";
}
.fa-ribbon:before {
	content: "\f4d6";
}
.fa-route:before {
	content: "\f4d7";
}
.fa-seedling:before {
	content: "\f4d8";
}
.fa-creative-commons-by:before {
	content: "\f4e7";
}
.fa-creative-commons-nc:before {
	content: "\f4e8";
}
.fa-creative-commons-nc-jp:before {
	content: "\f4ea";
}
.fa-creative-commons-nd:before {
	content: "\f4eb";
}
.fa-creative-commons-pd:before {
	content: "\f4ec";
}
.fa-creative-commons-pd-alt:before {
	content: "\f4ed";
}
.fa-creative-commons-remix:before {
	content: "\f4ee";
}
.fa-creative-commons-sampling:before {
	content: "\f4f0";
}
.fa-creative-commons-sampling-plus:before {
	content: "\f4f1";
}
.fa-creative-commons-share:before {
	content: "\f4f2";
}
.fa-user-cog:before {
	content: "\f4fe";
}
.fa-user-friends:before {
	content: "\f500";
}
.fa-user-slash:before {
	content: "\f506";
}
.fa-user-tie:before {
	content: "\f508";
}
.fa-balance-scale-left:before {
	content: "\f515";
}
.fa-balance-scale-right:before {
	content: "\f516";
}
.fa-blender:before {
	content: "\f517";
}
.fa-book-open:before {
	content: "\f518";
}
.fa-broadcast-tower:before {
	content: "\f519";
}
.fa-broom:before {
	content: "\f51a";
}
.fa-chalkboard:before {
	content: "\f51b";
}
.fa-chalkboard-teacher:before {
	content: "\f51c";
}
.fa-church:before {
	content: "\f51d";
}
.fa-coins:before {
	content: "\f51e";
}
.fa-compact-disc:before {
	content: "\f51f";
}
.fa-crow:before {
	content: "\f520";
}
.fa-crown:before {
	content: "\f521";
}
.fa-dice:before {
	content: "\f522";
}
.fa-dice-five:before {
	content: "\f523";
}
.fa-dice-four:before {
	content: "\f524";
}
.fa-dice-one:before {
	content: "\f525";
}
.fa-dice-six:before {
	content: "\f526";
}
.fa-dice-three:before {
	content: "\f527";
}
.fa-dice-two:before {
	content: "\f528";
}
.fa-divide:before {
	content: "\f529";
}
.fa-door-closed:before {
	content: "\f52a";
}
.fa-door-open:before {
	content: "\f52b";
}
.fa-equals:before {
	content: "\f52c";
}
.fa-feather:before {
	content: "\f52d";
}
.fa-frog:before {
	content: "\f52e";
}
.fa-gas-pump:before {
	content: "\f52f";
}
.fa-glasses:before {
	content: "\f530";
}
.fa-greater-than:before {
	content: "\f531";
}
.fa-greater-than-equal:before {
	content: "\f532";
}
.fa-helicopter:before {
	content: "\f533";
}
.fa-infinity:before {
	content: "\f534";
}
.fa-kiwi-bird:before {
	content: "\f535";
}
.fa-less-than:before {
	content: "\f536";
}
.fa-less-than-equal:before {
	content: "\f537";
}
.fa-memory:before {
	content: "\f538";
}
.fa-microphone-alt-slash:before {
	content: "\f539";
}
.fa-money-bill-wave:before {
	content: "\f53a";
}
.fa-money-bill-wave-alt:before {
	content: "\f53b";
}
.fa-money-check:before {
	content: "\f53c";
}
.fa-money-check-alt:before {
	content: "\f53d";
}
.fa-not-equal:before {
	content: "\f53e";
}
.fa-palette:before {
	content: "\f53f";
}
.fa-percentage:before {
	content: "\f541";
}
.fa-project-diagram:before {
	content: "\f542";
}
.fa-receipts:before {
	content: "\f543";
}
.fa-robot:before {
	content: "\f544";
}
.fa-ruler:before {
	content: "\f545";
}
.fa-school:before {
	content: "\f549";
}
.fa-screwdriver:before {
	content: "\f54a";
}
.fa-shoe-prints:before {
	content: "\f54b";
}
.fa-skull:before {
	content: "\f54c";
}
.fa-store:before {
	content: "\f54e";
}
.fa-toolbox:before {
	content: "\f552";
}
.fa-tshirt:before {
	content: "\f553";
}
.fa-wallet:before {
	content: "\f555";
}
.fa-angry:before {
	content: "\f556";
}
.fa-archway:before {
	content: "\f557";
}
.fa-atlas:before {
	content: "\f558";
}
.fa-award:before {
	content: "\f559";
}
.fa-backspace:before {
	content: "\f55a";
}
.fa-bezier-curve:before {
	content: "\f55b";
}
.fa-bong:before {
	content: "\f55c";
}
.fa-brush:before {
	content: "\f55d";
}
.fa-cannabis:before {
	content: "\f55e";
}
.fa-check-double:before {
	content: "\f560";
}
.fa-cocktail:before {
	content: "\f561";
}
.fa-concierge-bell:before {
	content: "\f562";
}
.fa-cookie:before {
	content: "\f563";
}
.fa-cookie-bite:before {
	content: "\f564";
}
.fa-crop-alt:before {
	content: "\f565";
}
.fa-digital-tachograph:before {
	content: "\f566";
}
.fa-dizzy:before {
	content: "\f567";
}
.fa-drafting-compass:before {
	content: "\f568";
}
.fa-drum:before {
	content: "\f569";
}
.fa-drum-steelpan:before {
	content: "\f56a";
}
.fa-feather-alt:before {
	content: "\f56b";
}
.fa-file-contract:before {
	content: "\f56c";
}
.fa-file-download:before {
	content: "\f56d";
}
.fa-file-export:before {
	content: "\f56e";
}
.fa-file-import:before {
	content: "\f56f";
}
.fa-file-invoice:before {
	content: "\f570";
}
.fa-file-invoice-dollar:before {
	content: "\f571";
}
.fa-file-prescription:before {
	content: "\f572";
}
.fa-file-certificate:before {
	content: "\f573";
}
.fa-file-upload:before {
	content: "\f574";
}
.fa-fill:before {
	content: "\f575";
}
.fa-fill-drip:before {
	content: "\f576";
}
.fa-fingerprint:before {
	content: "\f577";
}
.fa-fish:before {
	content: "\f578";
}
.fa-flushed:before {
	content: "\f579";
}
.fa-frown-open:before {
	content: "\f57a";
}
.fa-glass-martini-alt:before {
	content: "\f57b";
}
.fa-globe-africa:before {
	content: "\f57c";
}
.fa-globe-americas:before {
	content: "\f57d";
}
.fa-globe-asia:before {
	content: "\f57e";
}
.fa-grimace:before {
	content: "\f57f";
}
.fa-grin:before {
	content: "\f580";
}
.fa-grin-alt:before {
	content: "\f581";
}
.fa-grin-beam:before {
	content: "\f582";
}
.fa-grin-beam-sweat:before {
	content: "\f583";
}
.fa-grin-hearts:before {
	content: "\f584";
}
.fa-grin-squint:before {
	content: "\f585";
}
.fa-grin-squint-tears:before {
	content: "\f586";
}
.fa-grin-stars:before {
	content: "\f587";
}
.fa-grin-tears:before {
	content: "\f588";
}
.fa-grin-tongue:before {
	content: "\f589";
}
.fa-grin-tongue-squint:before {
	content: "\f58a";
}
.fa-grin-tongue-wink:before {
	content: "\f58b";
}
.fa-grin-wink:before {
	content: "\f58c";
}
.fa-grip-horizontal:before {
	content: "\f58d";
}
.fa-grip-vertical:before {
	content: "\f58e";
}
.fa-headphones-alt:before {
	content: "\f58f";
}
.fa-highlighter:before {
	content: "\f591";
}
.fa-hot-tub:before {
	content: "\f593";
}
.fa-hotel:before {
	content: "\f594";
}
.fa-joint:before {
	content: "\f595";
}
.fa-kiss:before {
	content: "\f596";
}
.fa-kiss-beam:before {
	content: "\f597";
}
.fa-kiss-wink-heart:before {
	content: "\f598";
}
.fa-laugh:before {
	content: "\f599";
}
.fa-laugh-beam:before {
	content: "\f59a";
}
.fa-laugh-squint:before {
	content: "\f59b";
}
.fa-laugh-wink:before {
	content: "\f59c";
}
.fa-luggage-cart:before {
	content: "\f59d";
}
.fa-map-marked:before {
	content: "\f59f";
}
.fa-map-marked-alt:before {
	content: "\f5a0";
}
.fa-marker:before {
	content: "\f5a1";
}
.fa-medal:before {
	content: "\f5a2";
}
.fa-meh-blank:before {
	content: "\f5a4";
}
.fa-meh-rolling-eyes:before {
	content: "\f5a5";
}
.fa-monument:before {
	content: "\f5a6";
}
.fa-mortar-pestle:before {
	content: "\f5a7";
}
.fa-paint-roller:before {
	content: "\f5aa";
}
.fa-passport:before {
	content: "\f5ab";
}
.fa-prescription:before {
	content: "\f5b1";
}
.fa-shuttle-van:before {
	content: "\f5b6";
}
.fa-signature:before {
	content: "\f5b7";
}
.fa-solar-panel:before {
	content: "\f5ba";
}
.fa-spray-can:before {
	content: "\f5bd";
}
.fa-stamp:before {
	content: "\f5bf";
}
.fa-swimmer:before {
	content: "\f5c4";
}
.fa-tooth:before {
	content: "\f5c9";
}
.fa-weight-hanging:before {
	content: "\f5cd";
}
.fa-air-freshener:before {
	content: "\f5d0";
}
.fa-apple-alt:before {
	content: "\f5d1";
}
.fa-atom:before {
	content: "\f5d2";
}
.fa-atom-alt:before {
	content: "\f5d3";
}
.fa-backpack:before {
	content: "\f5d4";
}
.fa-bell-school:before {
	content: "\f5d5";
}
.fa-bell-school-slash:before {
	content: "\f5d6";
}
.fa-bone:before {
	content: "\f5d7";
}
.fa-bone-break:before {
	content: "\f5d8";
}
.fa-book-alt:before {
	content: "\f5d9";
}
.fa-book-reader:before {
	content: "\f5da";
}
.fa-books:before {
	content: "\f5db";
}
.fa-brain:before {
	content: "\f5dc";
}
.fa-bus-school:before {
	content: "\f5dd";
}
.fa-car-alt:before {
	content: "\f5de";
}
.fa-car-battery:before {
	content: "\f5df";
}
.fa-car-bump:before {
	content: "\f5e0";
}
.fa-car-crash:before {
	content: "\f5e1";
}
.fa-car-garage:before {
	content: "\f5e2";
}
.fa-car-mechanic:before {
	content: "\f5e3";
}
.fa-car-side:before {
	content: "\f5e4";
}
.fa-car-tilt:before {
	content: "\f5e5";
}
.fa-car-wash:before {
	content: "\f5e6";
}
.fa-charging-station:before {
	content: "\f5e7";
}
.fa-clipboard-prescription:before {
	content: "\f5e8";
}
.fa-compass-slash:before {
	content: "\f5e9";
}
.fa-diploma:before {
	content: "\f5ea";
}
.fa-directions:before {
	content: "\f5eb";
}
.fa-do-not-enter:before {
	content: "\f5ec";
}
.fa-draw-circle:before {
	content: "\f5ed";
}
.fa-draw-polygon:before {
	content: "\f5ee";
}
.fa-draw-square:before {
	content: "\f5ef";
}
.fa-ear:before {
	content: "\f5f0";
}
.fa-engine-warning:before {
	content: "\f5f2";
}
.fa-gas-pump-slash:before {
	content: "\f5f4";
}
.fa-glasses-alt:before {
	content: "\f5f5";
}
.fa-globe-stand:before {
	content: "\f5f6";
}
.fa-heart-rate:before {
	content: "\f5f8";
}
.fa-inhaler:before {
	content: "\f5f9";
}
.fa-kidneys:before {
	content: "\f5fb";
}
.fa-laptop-code:before {
	content: "\f5fc";
}
.fa-layer-group:before {
	content: "\f5fd";
}
.fa-layer-minus:before {
	content: "\f5fe";
}
.fa-layer-plus:before {
	content: "\f5ff";
}
.fa-lips:before {
	content: "\f600";
}
.fa-location:before {
	content: "\f601";
}
.fa-location-slash:before {
	content: "\f603";
}
.fa-lungs:before {
	content: "\f604";
}
.fa-map-marker-alt-slash:before {
	content: "\f605";
}
.fa-map-marker-check:before {
	content: "\f606";
}
.fa-map-marker-edit:before {
	content: "\f607";
}
.fa-map-marker-exclamation:before {
	content: "\f608";
}
.fa-map-marker-minus:before {
	content: "\f609";
}
.fa-map-marker-question:before {
	content: "\f60b";
}
.fa-map-marker-slash:before {
	content: "\f60c";
}
.fa-map-marker-smile:before {
	content: "\f60d";
}
.fa-map-marker-times:before {
	content: "\f60e";
}
.fa-microscope:before {
	content: "\f610";
}
.fa-monitor-heart-rate:before {
	content: "\f611";
}
.fa-oil-can:before {
	content: "\f613";
}
.fa-parking-circle:before {
	content: "\f615";
}
.fa-route-highway:before {
	content: "\f61a";
}
.fa-shapes:before {
	content: "\f61f";
}
.fa-steering-wheel:before {
	content: "\f622";
}
.fa-stomach:before {
	content: "\f623";
}
.fa-teeth-open:before {
	content: "\f62f";
}
.fa-tire:before {
	content: "\f631";
}
.fa-traffic-cone:before {
	content: "\f636";
}
.fa-traffic-light:before {
	content: "\f637";
}
.fa-users-class:before {
	content: "\f63d";
}
.fa-abacus:before {
	content: "\f640";
}
.fa-ad:before {
	content: "\f641";
}
.fa-alipay:before {
	content: "\f642";
}
.fa-analytics:before {
	content: "\f643";
}
.fa-ankh:before {
	content: "\f644";
}
.fa-badge-dollar:before {
	content: "\f645";
}
.fa-badge-percent:before {
	content: "\f646";
}
.fa-bible:before {
	content: "\f647";
}
.fa-bullseye-arrow:before {
	content: "\f648";
}
.fa-bullseye-pointer:before {
	content: "\f649";
}
.fa-business-time:before {
	content: "\f64a";
}
.fa-cabinet-filing:before {
	content: "\f64b";
}
.fa-calculator-alt:before {
	content: "\f64c";
}
.fa-chart-line-down:before {
	content: "\f64d";
}
.fa-chart-pie-alt:before {
	content: "\f64e";
}
.fa-city:before {
	content: "\f64f";
}
.fa-comment-dollar:before {
	content: "\f651";
}
.fa-comments-alt-dollar:before {
	content: "\f652";
}
.fa-comments-dollar:before {
	content: "\f653";
}
.fa-cross:before {
	content: "\f654";
}
.fa-dharmachakra:before {
	content: "\f655";
}
.fa-empty-set:before {
	content: "\f656";
}
.fa-envelope-open-dollar:before {
	content: "\f657";
}
.fa-envelope-open-text:before {
	content: "\f658";
}
.fa-file-chart-line:before {
	content: "\f659";
}
.fa-file-chart-pie:before {
	content: "\f65a";
}
.fa-file-spreadsheet:before {
	content: "\f65b";
}
.fa-file-user:before {
	content: "\f65c";
}
.fa-folder-minus:before {
	content: "\f65d";
}
.fa-folder-plus:before {
	content: "\f65e";
}
.fa-folder-times:before {
	content: "\f65f";
}
.fa-folders:before {
	content: "\f660";
}
.fa-function:before {
	content: "\f661";
}
.fa-funnel-dollar:before {
	content: "\f662";
}
.fa-gift-card:before {
	content: "\f663";
}
.fa-gopuram:before {
	content: "\f664";
}
.fa-hamsa:before {
	content: "\f665";
}
.fa-bahai:before {
	content: "\f666";
}
.fa-integral:before {
	content: "\f667";
}
.fa-intersection:before {
	content: "\f668";
}
.fa-jedi:before {
	content: "\f669";
}
.fa-journal-whills:before {
	content: "\f66a";
}
.fa-kaaba:before {
	content: "\f66b";
}
.fa-keynote:before {
	content: "\f66c";
}
.fa-khanda:before {
	content: "\f66d";
}
.fa-lambda:before {
	content: "\f66e";
}
.fa-landmark:before {
	content: "\f66f";
}
.fa-lightbulb-dollar:before {
	content: "\f670";
}
.fa-lightbulb-exclamation:before {
	content: "\f671";
}
.fa-lightbulb-on:before {
	content: "\f672";
}
.fa-lightbulb-slash:before {
	content: "\f673";
}
.fa-megaphone:before {
	content: "\f675";
}
.fa-menorah:before {
	content: "\f676";
}
.fa-mind-share:before {
	content: "\f677";
}
.fa-mosque:before {
	content: "\f678";
}
.fa-om:before {
	content: "\f679";
}
.fa-omega:before {
	content: "\f67a";
}
.fa-pastafarianism:before {
	content: "\f67b";
}
.fa-peace:before {
	content: "\f67c";
}
.fa-pi:before {
	content: "\f67e";
}
.fa-praying-hands:before {
	content: "\f684";
}
.fa-presentation:before {
	content: "\f685";
}
.fa-quran:before {
	content: "\f687";
}
.fa-sigma:before {
	content: "\f68b";
}
.fa-signal-alt-2:before {
	content: "\f692";
}
.fa-socks:before {
	content: "\f696";
}
.fa-square-root:before {
	content: "\f697";
}
.fa-user-chart:before {
	content: "\f6a3";
}
.fa-volume:before {
	content: "\f6a8";
}
.fa-wifi-slash:before {
	content: "\f6ac";
}
.fa-yin-yang:before {
	content: "\f6ad";
}
.fa-acorn:before {
	content: "\f6ae";
}
.fa-acquisitions-incorporated:before {
	content: "\f6af";
}
.fa-alicorn:before {
	content: "\f6b0";
}
.fa-apple-crate:before {
	content: "\f6b1";
}
.fa-axe:before {
	content: "\f6b2";
}
.fa-axe-battle:before {
	content: "\f6b3";
}
.fa-badger-honey:before {
	content: "\f6b4";
}
.fa-bat:before {
	content: "\f6b5";
}
.fa-blender-phone:before {
	content: "\f6b6";
}
.fa-book-dead:before {
	content: "\f6b7";
}
.fa-book-spells:before {
	content: "\f6b8";
}
.fa-bow-arrow:before {
	content: "\f6b9";
}
.fa-campfire:before {
	content: "\f6ba";
}
.fa-campground:before {
	content: "\f6bb";
}
.fa-candle-holder:before {
	content: "\f6bc";
}
.fa-candy-corn:before {
	content: "\f6bd";
}
.fa-cat:before {
	content: "\f6be";
}
.fa-cauldron:before {
	content: "\f6bf";
}
.fa-chair:before {
	content: "\f6c0";
}
.fa-chair-office:before {
	content: "\f6c1";
}
.fa-claw-marks:before {
	content: "\f6c2";
}
.fa-cloud-moon:before {
	content: "\f6c3";
}
.fa-cloud-sun:before {
	content: "\f6c4";
}
.fa-coffee-togo:before {
	content: "\f6c5";
}
.fa-coffin:before {
	content: "\f6c6";
}
.fa-corn:before {
	content: "\f6c7";
}
.fa-cow:before {
	content: "\f6c8";
}
.fa-critical-role:before {
	content: "\f6c9";
}
.fa-d-and-d-beyond:before {
	content: "\f6ca";
}
.fa-dagger:before {
	content: "\f6cb";
}
.fa-dice-d10:before {
	content: "\f6cd";
}
.fa-dice-d12:before {
	content: "\f6ce";
}
.fa-dice-d20:before {
	content: "\f6cf";
}
.fa-dice-d4:before {
	content: "\f6d0";
}
.fa-dice-d6:before {
	content: "\f6d1";
}
.fa-dice-d8:before {
	content: "\f6d2";
}
.fa-dog:before {
	content: "\f6d3";
}
.fa-dog-leashed:before {
	content: "\f6d4";
}
.fa-dragon:before {
	content: "\f6d5";
}
.fa-drumstick:before {
	content: "\f6d6";
}
.fa-drumstick-bite:before {
	content: "\f6d7";
}
.fa-duck:before {
	content: "\f6d8";
}
.fa-dungeon:before {
	content: "\f6d9";
}
.fa-elephant:before {
	content: "\f6da";
}
.fa-eye-evil:before {
	content: "\f6db";
}
.fa-file-csv:before {
	content: "\f6dd";
}
.fa-fist-raised:before {
	content: "\f6de";
}
.fa-flame:before {
	content: "\f6df";
}
.fa-flask-poison:before {
	content: "\f6e0";
}
.fa-flask-potion:before {
	content: "\f6e1";
}
.fa-ghost:before {
	content: "\f6e2";
}
.fa-hammer:before {
	content: "\f6e3";
}
.fa-hammer-war:before {
	content: "\f6e4";
}
.fa-hand-holding-magic:before {
	content: "\f6e5";
}
.fa-hanukiah:before {
	content: "\f6e6";
}
.fa-hat-witch:before {
	content: "\f6e7";
}
.fa-hat-wizard:before {
	content: "\f6e8";
}
.fa-head-side:before {
	content: "\f6e9";
}
.fa-head-vr:before {
	content: "\f6ea";
}
.fa-helmet-battle:before {
	content: "\f6eb";
}
.fa-hiking:before {
	content: "\f6ec";
}
.fa-hippo:before {
	content: "\f6ed";
}
.fa-hockey-mask:before {
	content: "\f6ee";
}
.fa-hood-cloak:before {
	content: "\f6ef";
}
.fa-horse:before {
	content: "\f6f0";
}
.fa-house-damage:before {
	content: "\f6f1";
}
.fa-hryvnia:before {
	content: "\f6f2";
}
.fa-key-skeleton:before {
	content: "\f6f3";
}
.fa-kite:before {
	content: "\f6f4";
}
.fa-knife-kitchen:before {
	content: "\f6f5";
}
.fa-leaf-maple:before {
	content: "\f6f6";
}
.fa-leaf-oak:before {
	content: "\f6f7";
}
.fa-mace:before {
	content: "\f6f8";
}
.fa-mandolin:before {
	content: "\f6f9";
}
.fa-mask:before {
	content: "\f6fa";
}
.fa-monkey:before {
	content: "\f6fb";
}
.fa-mountain:before {
	content: "\f6fc";
}
.fa-mountains:before {
	content: "\f6fd";
}
.fa-network-wired:before {
	content: "\f6ff";
}
.fa-otter:before {
	content: "\f700";
}
.fa-pie:before {
	content: "\f705";
}
.fa-pumpkin:before {
	content: "\f707";
}
.fa-rabbit:before {
	content: "\f708";
}
.fa-ram:before {
	content: "\f70a";
}
.fa-running:before {
	content: "\f70c";
}
.fa-scarecrow:before {
	content: "\f70d";
}
.fa-scroll:before {
	content: "\f70e";
}
.fa-shovel:before {
	content: "\f713";
}
.fa-slash:before {
	content: "\f715";
}
.fa-snake:before {
	content: "\f716";
}
.fa-spider:before {
	content: "\f717";
}
.fa-spider-web:before {
	content: "\f719";
}
.fa-squirrel:before {
	content: "\f71a";
}
.fa-staff:before {
	content: "\f71b";
}
.fa-sword:before {
	content: "\f71c";
}
.fa-toilet-paper:before {
	content: "\f71e";
}
.fa-tombstone:before {
	content: "\f720";
}
.fa-turtle:before {
	content: "\f726";
}
.fa-vr-cardboard:before {
	content: "\f729";
}
.fa-whale:before {
	content: "\f72c";
}
.fa-wheat:before {
	content: "\f72d";
}
.fa-ballot:before {
	content: "\f732";
}
.fa-ballot-check:before {
	content: "\f733";
}
.fa-booth-curtain:before {
	content: "\f734";
}
.fa-box-ballot:before {
	content: "\f735";
}
.fa-calendar-star:before {
	content: "\f736";
}
.fa-clipboard-list-check:before {
	content: "\f737";
}
.fa-cloud-drizzle:before {
	content: "\f738";
}
.fa-cloud-hail:before {
	content: "\f739";
}
.fa-cloud-hail-mixed:before {
	content: "\f73a";
}
.fa-cloud-meatball:before {
	content: "\f73b";
}
.fa-cloud-moon-rain:before {
	content: "\f73c";
}
.fa-cloud-rain:before {
	content: "\f73d";
}
.fa-cloud-rainbow:before {
	content: "\f73e";
}
.fa-cloud-showers:before {
	content: "\f73f";
}
.fa-cloud-showers-heavy:before {
	content: "\f740";
}
.fa-cloud-sleet:before {
	content: "\f741";
}
.fa-cloud-snow:before {
	content: "\f742";
}
.fa-cloud-sun-rain:before {
	content: "\f743";
}
.fa-clouds:before {
	content: "\f744";
}
.fa-clouds-moon:before {
	content: "\f745";
}
.fa-clouds-sun:before {
	content: "\f746";
}
.fa-democrat:before {
	content: "\f747";
}
.fa-dewpoint:before {
	content: "\f748";
}
.fa-eclipse:before {
	content: "\f749";
}
.fa-eclipse-alt:before {
	content: "\f74a";
}
.fa-fire-smoke:before {
	content: "\f74b";
}
.fa-flag-alt:before {
	content: "\f74c";
}
.fa-flag-usa:before {
	content: "\f74d";
}
.fa-fog:before {
	content: "\f74e";
}
.fa-house-flood:before {
	content: "\f74f";
}
.fa-humidity:before {
	content: "\f750";
}
.fa-hurricane:before {
	content: "\f751";
}
.fa-landmark-alt:before {
	content: "\f752";
}
.fa-meteor:before {
	content: "\f753";
}
.fa-moon-cloud:before {
	content: "\f754";
}
.fa-moon-stars:before {
	content: "\f755";
}
.fa-podium-star:before {
	content: "\f758";
}
.fa-raindrops:before {
	content: "\f75c";
}
.fa-smog:before {
	content: "\f75f";
}
.fa-thunderstorm:before {
	content: "\f76c";
}
.fa-volcano:before {
	content: "\f770";
}
.fa-water:before {
	content: "\f773";
}
.fa-angel:before {
	content: "\f779";
}
.fa-artstation:before {
	content: "\f77a";
}
.fa-atlassian:before {
	content: "\f77b";
}
.fa-baby:before {
	content: "\f77c";
}
.fa-baby-carriage:before {
	content: "\f77d";
}
.fa-ball-pile:before {
	content: "\f77e";
}
.fa-bells:before {
	content: "\f77f";
}
.fa-biohazard:before {
	content: "\f780";
}
.fa-blog:before {
	content: "\f781";
}
.fa-boot:before {
	content: "\f782";
}
.fa-calendar-day:before {
	content: "\f783";
}
.fa-calendar-week:before {
	content: "\f784";
}
.fa-canadian-maple-leaf:before {
	content: "\f785";
}
.fa-candy-cane:before {
	content: "\f786";
}
.fa-carrot:before {
	content: "\f787";
}
.fa-cash-register:before {
	content: "\f788";
}
.fa-centos:before {
	content: "\f789";
}
.fa-chart-network:before {
	content: "\f78a";
}
.fa-chimney:before {
	content: "\f78b";
}
.fa-compress-arrows-alt:before {
	content: "\f78c";
}
.fa-confluence:before {
	content: "\f78d";
}
.fa-deer:before {
	content: "\f78e";
}
.fa-deer-rudolph:before {
	content: "\f78f";
}
.fa-diaspora:before {
	content: "\f791";
}
.fa-dreidel:before {
	content: "\f792";
}
.fa-dumpster:before {
	content: "\f793";
}
.fa-dumpster-fire:before {
	content: "\f794";
}
.fa-ear-muffs:before {
	content: "\f795";
}
.fa-ethernet:before {
	content: "\f796";
}
.fa-fireplace:before {
	content: "\f79a";
}
.fa-frosty-head:before {
	content: "\f79b";
}
.fa-gifts:before {
	content: "\f79c";
}
.fa-gingerbread-man:before {
	content: "\f79d";
}
.fa-glass-champagne:before {
	content: "\f79e";
}
.fa-glass-cheers:before {
	content: "\f79f";
}
.fa-glass-whiskey:before {
	content: "\f7a0";
}
.fa-glass-whiskey-rocks:before {
	content: "\f7a1";
}
.fa-globe-europe:before {
	content: "\f7a2";
}
.fa-globe-snow:before {
	content: "\f7a3";
}
.fa-grip-lines:before {
	content: "\f7a4";
}
.fa-grip-lines-vertical:before {
	content: "\f7a5";
}
.fa-guitar:before {
	content: "\f7a6";
}
.fa-hat-santa:before {
	content: "\f7a7";
}
.fa-hat-winter:before {
	content: "\f7a8";
}
.fa-heart-broken:before {
	content: "\f7a9";
}
.fa-holly-berry:before {
	content: "\f7aa";
}
.fa-horse-head:before {
	content: "\f7ab";
}
.fa-ice-skate:before {
	content: "\f7ac";
}
.fa-icicles:before {
	content: "\f7ad";
}
.fa-igloo:before {
	content: "\f7ae";
}
.fa-lights-holiday:before {
	content: "\f7b2";
}
.fa-mistletoe:before {
	content: "\f7b4";
}
.fa-mitten:before {
	content: "\f7b5";
}
.fa-mug-hot:before {
	content: "\f7b6";
}
.fa-mug-marshmallows:before {
	content: "\f7b7";
}
.fa-ornament:before {
	content: "\f7b8";
}
.fa-radiation-alt:before {
	content: "\f7ba";
}
.fa-restroom:before {
	content: "\f7bd";
}
.fa-satellite:before {
	content: "\f7bf";
}
.fa-scarf:before {
	content: "\f7c1";
}
.fa-sd-card:before {
	content: "\f7c2";
}
.fa-sim-card:before {
	content: "\f7c4";
}
.fa-sleigh:before {
	content: "\f7cc";
}
.fa-sms:before {
	content: "\f7cd";
}
.fa-snowman:before {
	content: "\f7d0";
}
.fa-toilet:before {
	content: "\f7d8";
}
.fa-tools:before {
	content: "\f7d9";
}
.fa-fire-alt:before {
	content: "\f7e4";
}
.fa-bacon:before {
	content: "\f7e5";
}
.fa-book-medical:before {
	content: "\f7e6";
}
.fa-book-user:before {
	content: "\f7e7";
}
.fa-books-medical:before {
	content: "\f7e8";
}
.fa-brackets:before {
	content: "\f7e9";
}
.fa-brackets-curly:before {
	content: "\f7ea";
}
.fa-bread-loaf:before {
	content: "\f7eb";
}
.fa-bread-slice:before {
	content: "\f7ec";
}
.fa-burrito:before {
	content: "\f7ed";
}
.fa-chart-scatter:before {
	content: "\f7ee";
}
.fa-cheese:before {
	content: "\f7ef";
}
.fa-cheese-swiss:before {
	content: "\f7f0";
}
.fa-cheeseburger:before {
	content: "\f7f1";
}
.fa-clinic-medical:before {
	content: "\f7f2";
}
.fa-clipboard-user:before {
	content: "\f7f3";
}
.fa-comment-alt-medical:before {
	content: "\f7f4";
}
.fa-comment-medical:before {
	content: "\f7f5";
}
.fa-croissant:before {
	content: "\f7f6";
}
.fa-crutch:before {
	content: "\f7f7";
}
.fa-crutches:before {
	content: "\f7f8";
}
.fa-debug:before {
	content: "\f7f9";
}
.fa-disease:before {
	content: "\f7fa";
}
.fa-egg:before {
	content: "\f7fb";
}
.fa-egg-fried:before {
	content: "\f7fc";
}
.fa-files-medical:before {
	content: "\f7fd";
}
.fa-fish-cooked:before {
	content: "\f7fe";
}
.fa-flower:before {
	content: "\f7ff";
}
.fa-flower-daffodil:before {
	content: "\f800";
}
.fa-flower-tulip:before {
	content: "\f801";
}
.fa-folder-tree:before {
	content: "\f802";
}
.fa-french-fries:before {
	content: "\f803";
}
.fa-glass:before {
	content: "\f804";
}
.fa-hamburger:before {
	content: "\f805";
}
.fa-hand-middle-finger:before {
	content: "\f806";
}
.fa-hard-hat:before {
	content: "\f807";
}
.fa-head-side-brain:before {
	content: "\f808";
}
.fa-head-side-medical:before {
	content: "\f809";
}
.fa-home-alt:before {
	content: "\f80a";
}
.fa-home-lg:before {
	content: "\f80b";
}
.fa-home-lg-alt:before {
	content: "\f80c";
}
.fa-hospital-user:before {
	content: "\f80d";
}
.fa-hospitals:before {
	content: "\f80e";
}
.fa-hotdog:before {
	content: "\f80f";
}
.fa-ice-cream:before {
	content: "\f810";
}
.fa-island-tropical:before {
	content: "\f811";
}
.fa-laptop-medical:before {
	content: "\f812";
}
.fa-mailbox:before {
	content: "\f813";
}
.fa-meat:before {
	content: "\f814";
}
.fa-pager:before {
	content: "\f815";
}
.fa-pepper-hot:before {
	content: "\f816";
}
.fa-pizza-slice:before {
	content: "\f818";
}
.fa-popcorn:before {
	content: "\f819";
}
.fa-rings-wedding:before {
	content: "\f81b";
}
.fa-sack-dollar:before {
	content: "\f81d";
}
.fa-salad:before {
	content: "\f81e";
}
.fa-sandwich:before {
	content: "\f81f";
}
.fa-sausage:before {
	content: "\f820";
}
.fa-soup:before {
	content: "\f823";
}
.fa-steak:before {
	content: "\f824";
}
.fa-stretcher:before {
	content: "\f825";
}
.fa-user-headset:before {
	content: "\f82d";
}
.fa-users-medical:before {
	content: "\f830";
}
.fa-walker:before {
	content: "\f831";
}
.fa-webcam:before {
	content: "\f832";
}
.fa-airbnb:before {
	content: "\f834";
}
.fa-battle-net:before {
	content: "\f835";
}
.fa-bootstrap:before {
	content: "\f836";
}
.fa-chromecast:before {
	content: "\f838";
}
.fa-alarm-exclamation:before {
	content: "\f843";
}
.fa-alarm-plus:before {
	content: "\f844";
}
.fa-alarm-snooze:before {
	content: "\f845";
}
.fa-align-slash:before {
	content: "\f846";
}
.fa-bags-shopping:before {
	content: "\f847";
}
.fa-bell-exclamation:before {
	content: "\f848";
}
.fa-bell-plus:before {
	content: "\f849";
}
.fa-biking:before {
	content: "\f84a";
}
.fa-biking-mountain:before {
	content: "\f84b";
}
.fa-border-all:before {
	content: "\f84c";
}
.fa-border-bottom:before {
	content: "\f84d";
}
.fa-border-inner:before {
	content: "\f84e";
}
.fa-border-left:before {
	content: "\f84f";
}
.fa-border-none:before {
	content: "\f850";
}
.fa-border-outer:before {
	content: "\f851";
}
.fa-border-right:before {
	content: "\f852";
}
.fa-border-style:before {
	content: "\f853";
}
.fa-border-style-alt:before {
	content: "\f854";
}
.fa-border-top:before {
	content: "\f855";
}
.fa-bring-forward:before {
	content: "\f856";
}
.fa-bring-front:before {
	content: "\f857";
}
.fa-burger-soda:before {
	content: "\f858";
}
.fa-car-building:before {
	content: "\f859";
}
.fa-car-bus:before {
	content: "\f85a";
}
.fa-cars:before {
	content: "\f85b";
}
.fa-coin:before {
	content: "\f85c";
}
.fa-construction:before {
	content: "\f85d";
}
.fa-digging:before {
	content: "\f85e";
}
.fa-drone:before {
	content: "\f85f";
}
.fa-drone-alt:before {
	content: "\f860";
}
.fa-dryer:before {
	content: "\f861";
}
.fa-dryer-alt:before {
	content: "\f862";
}
.fa-fan:before {
	content: "\f863";
}
.fa-farm:before {
	content: "\f864";
}
.fa-file-search:before {
	content: "\f865";
}
.fa-font-case:before {
	content: "\f866";
}
.fa-game-board:before {
	content: "\f867";
}
.fa-game-board-alt:before {
	content: "\f868";
}
.fa-glass-citrus:before {
	content: "\f869";
}
.fa-h4:before {
	content: "\f86a";
}
.fa-hat-chef:before {
	content: "\f86b";
}
.fa-horizontal-rule:before {
	content: "\f86c";
}
.fa-icons:before {
	content: "\f86d";
}
.fa-kerning:before {
	content: "\f86f";
}
.fa-line-columns:before {
	content: "\f870";
}
.fa-line-height:before {
	content: "\f871";
}
.fa-money-check-edit:before {
	content: "\f872";
}
.fa-money-check-edit-alt:before {
	content: "\f873";
}
.fa-mug:before {
	content: "\f874";
}
.fa-phone-alt:before {
	content: "\f879";
}
.fa-snooze:before {
	content: "\f880";
}
.fa-sort-alt:before {
	content: "\f883";
}
.fa-sort-amount-down-alt:before {
	content: "\f884";
}
.fa-sort-size-down:before {
	content: "\f88c";
}
.fa-sparkles:before {
	content: "\f890";
}
.fa-text:before {
	content: "\f893";
}
.fa-text-size:before {
	content: "\f894";
}
.fa-voicemail:before {
	content: "\f897";
}
.fa-washer:before {
	content: "\f898";
}
.fa-wind-turbine:before {
	content: "\f89b";
}
.fa-border-center-h:before {
	content: "\f89c";
}
.fa-border-center-v:before {
	content: "\f89d";
}
.fa-cotton-bureau:before {
	content: "\f89e";
}
.fa-album:before {
	content: "\f89f";
}
.fa-album-collection:before {
	content: "\f8a0";
}
.fa-amp-guitar:before {
	content: "\f8a1";
}
.fa-badge-sheriff:before {
	content: "\f8a2";
}
.fa-banjo:before {
	content: "\f8a3";
}
.fa-betamax:before {
	content: "\f8a4";
}
.fa-boombox:before {
	content: "\f8a5";
}
.fa-buy-n-large:before {
	content: "\f8a6";
}
.fa-cactus:before {
	content: "\f8a7";
}
.fa-camcorder:before {
	content: "\f8a8";
}
.fa-camera-movie:before {
	content: "\f8a9";
}
.fa-camera-polaroid:before {
	content: "\f8aa";
}
.fa-cassette-tape:before {
	content: "\f8ab";
}
.fa-cctv:before {
	content: "\f8ac";
}
.fa-clarinet:before {
	content: "\f8ad";
}
.fa-cloud-music:before {
	content: "\f8ae";
}
.fa-comment-alt-music:before {
	content: "\f8af";
}
.fa-comment-music:before {
	content: "\f8b0";
}
.fa-computer-classic:before {
	content: "\f8b1";
}
.fa-computer-speaker:before {
	content: "\f8b2";
}
.fa-cowbell:before {
	content: "\f8b3";
}
.fa-cowbell-more:before {
	content: "\f8b4";
}
.fa-disc-drive:before {
	content: "\f8b5";
}
.fa-file-music:before {
	content: "\f8b6";
}
.fa-film-canister:before {
	content: "\f8b7";
}
.fa-flashlight:before {
	content: "\f8b8";
}
.fa-flute:before {
	content: "\f8b9";
}
.fa-flux-capacitor:before {
	content: "\f8ba";
}
.fa-game-console-handheld:before {
	content: "\f8bb";
}
.fa-gamepad-alt:before {
	content: "\f8bc";
}
.fa-gramophone:before {
	content: "\f8bd";
}
.fa-guitar-electric:before {
	content: "\f8be";
}
.fa-guitars:before {
	content: "\f8bf";
}
.fa-hat-cowboy:before {
	content: "\f8c0";
}
.fa-hat-cowboy-side:before {
	content: "\f8c1";
}
.fa-head-side-headphones:before {
	content: "\f8c2";
}
.fa-horse-saddle:before {
	content: "\f8c3";
}
.fa-image-polaroid:before {
	content: "\f8c4";
}
.fa-joystick:before {
	content: "\f8c5";
}
.fa-jug:before {
	content: "\f8c6";
}
.fa-kazoo:before {
	content: "\f8c7";
}
.fa-lasso:before {
	content: "\f8c8";
}
.fa-list-music:before {
	content: "\f8c9";
}
.fa-microphone-stand:before {
	content: "\f8cb";
}
.fa-mouse:before {
	content: "\f8cc";
}
.fa-mouse-alt:before {
	content: "\f8cd";
}
.fa-mp3-player:before {
	content: "\f8ce";
}
.fa-music-slash:before {
	content: "\f8d1";
}
.fa-piano-keyboard:before {
	content: "\f8d5";
}
.fa-projector:before {
	content: "\f8d6";
}
.fa-radio-alt:before {
	content: "\f8d8";
}
.fa-router:before {
	content: "\f8da";
}
.fa-saxophone:before {
	content: "\f8dc";
}
.fa-speakers:before {
	content: "\f8e0";
}
.fa-trumpet:before {
	content: "\f8e3";
}
.fa-usb-drive:before {
	content: "\f8e9";
}
.fa-walkie-talkie:before {
	content: "\f8ef";
}
.fa-waveform:before {
	content: "\f8f1";
}
.fa-scanner-image:before {
	content: "\f8f3";
}
.fa-air-conditioner:before {
	content: "\f8f4";
}
.fa-alien:before {
	content: "\f8f5";
}
.fa-alien-monster:before {
	content: "\f8f6";
}
.fa-bed-alt:before {
	content: "\f8f7";
}
.fa-bed-bunk:before {
	content: "\f8f8";
}
.fa-bed-empty:before {
	content: "\f8f9";
}
.fa-bell-on:before {
	content: "\f8fa";
}
.fa-blinds:before {
	content: "\f8fb";
}
.fa-blinds-raised:before {
	content: "\f8fd";
}
.fa-camera-home:before {
	content: "\f8fe";
}
.fa-caravan:before {
	content: "\f8ff";
}
