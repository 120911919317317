@import "../assets/icons.scss";
@import "../components/user/UserStyles.module.scss";
@import "../components/account/AccountStle.module.scss";
@import "../components/settings/UserRoles.module.scss";
@import "../components/organization/OrganizationStyles.module.scss";
@import "../components/login/welcomeback.module.scss";
@import "../components/adminMenu/Adminmenu.scss";
@import "../components/tours/Tours.module.scss";
@import "../components/banners/banners.module.scss";
@import "../components//announcements/Announcements.module.scss";
:root {
	--font-family: Poppins, Proxima Nova, arial, serif;
	--content-background-color: linear-gradient(to right, #f6eeee, #f6eeee);
	--white-color: #fff;
	--border-color: #ededed;
	--button-bg-color: #5f9ea0;
	--box-bottom-shadow: 0px 0.5px 1px -0.5px rgb(0 0 0 / 5%), 0px 4px 6px 0px rgb(0 0 0 / 5%),
		0px 2px 2px 0px rgb(0 0 0 / 5%) !important;
	--box-right-shadow: 7px 0px 6px -3px rgb(0 0 0 / 3%), /* Larger right-side shadow */ 2px 0px 4px -2px rgb(0 0 0 / 3%),
		/* Medium right-side shadow */ 1px 0px 2px -1px rgb(0 0 0 / 3%) /* Subtle right-side shadow */ !important;
	--grid-border-color: #f6eeee;
	--grid-head-background: linear-gradient(to right, #f6eeee, #f6eeee);
	--tool-tip-bg: #334155;
	--font-size: 14px !important;
	--error-color: #d05353;
	--button-padding: 8px 12px;
	--button-lineheight: normal;
}

code {
	font-family: var(--font-family) !important;
}
* {
	font-family: var(--font-family) !important;
}
body {
	margin: 0;
	font-family: var(--font-family) !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
/* styles.css */

.fal,
.far,
.fad,
.fas,
.fab {
	font-family: "qadapt-icons" !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
}
i {
	font-size: 18px !important;
}

@font-face {
	font-family: "Gotham Pro";
	font-style: normal;
	// src: local("Gotham Pro"), local("Gotham Pro"), url("../assets/fonts/GothamPro.woff2") format("woff2"),
	// 	url("../assets/fonts/GothamPro.woff") format("woff");
}

@font-face {
	font-family: "Proxima Nova";
	font-style: normal;
	src: local("Proxima Nova"), local("ProximaNova-Regular"),
		url("../assets/fonts/ProximaNova-Regular.woff2") format("woff2"),
		url("../assets/fonts/ProximaNova-Regular.woff") format("woff");
}

@font-face {
	font-family: "qadapt-icons";
	src: url("../assets/fonts/qadapt-icons.eot?qmcsfb");
	src: url("../assets/fonts/qadapt-icons.eot?qmcsfb#iefix") format("embedded-opentype"),
		url("../assets/fonts/qadapt-icons.ttf?qmcsfb") format("truetype"),
		url("../assets/fonts/qadapt-icons.woff?qmcsfb") format("woff"),
		url("../assets/fonts/qadapt-icons.svg?qmcsfb#qadapt-icons") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.scrollbar-container {
	height: calc(100% - 50px) !important;
}
/* Banner Start */
.qadpt-banner {
	background-color: var(--white-color);
	border-bottom: 1px solid var(--border-color);
	width: 100%;
	height: 45px;
	z-index: 9;
	position: fixed;
	top: 0;
	display: flex;
	align-items: center;
	.menu-icon {
		cursor: pointer;
		height: 27px;
		width: 27px;
		margin: 5px;
		margin-right: 9px;
		margin-top: 7px;
		display: flex;
		align-items: center;
		place-content: center;
	}
	.adapat-banner-left {
		margin: 0 10px;
		height: 45px;
		position: relative;
		float: left;
		display: flex;
		align-items: center;
		place-content: center;
		i {
			cursor: pointer;
		}
		.qadpt-logo {
			width: 200px;
			height: auto;
			padding-top: 10px;
		}
	}
	.adapat-banner-right {
		position: absolute;
		display: flex;
		right: 9px;
	}
	.qadapt-logo {
		cursor: pointer;
		margin-left: 15px;
		color: rgba(34, 34, 34, 1);
		font-family: "Syncopate";
		font-size: 14px;
		font-weight: 700;
		line-height: 14.57px;
		letter-spacing: 0.3px;
		text-align: left;
	}
	.qxy-lang {
		width: 140px;
		display: flex;
		align-items: center;
		place-content: center;
		margin-right: 12px;
		.lang-input {
			border: 1px solid var(--border-color);
			border-radius: 8px;
			padding: 0 10px;
			background: var(--border-color);
			.MuiInputBase-input {
				padding: 5px !important;
				padding-right: 24px !important;
			}
			label + .lang-input-box {
				margin-top: 0 !important;
				&::before {
					border-bottom: 0 !important;
				}
			}
		}
	}
}
/* Banner End */
.qadpt-page-content {
	display: flex;
	width: 100%;
	/*side-menu start */
	.qadpt-side-menu {
		width: 240px;
		height: 100%;
		display: block;
		position: absolute;
		z-index: 9;
		.qadpt-smenu {
			.MuiDrawer-paper {
				width: 240px;
				border-right: 1px solid var(--border-color);
				top: 45px;
				background: var(--white-color);
				.qadpt-smenu-list {
					.qadpt-sm-item {
						margin-left: 10px;
						margin-top: 6px;
						width: 220px;
						height: 40px;
						padding: 12px 23px;
						gap: -5px;
						border-radius: 6px;
						display: flex;
						align-items: center;
						place-content: flex-start;
						white-space: nowrap;
						cursor: pointer;
						&:hover {
							background: rgba(95, 157, 159, 0.15);
							transition: background 0.3s ease;
						}

						&.active {
							background-color: rgba(95, 158, 160, 1);
							color: var(--white-color);
						}
						// &.inactive {
						// 	background-color: transparent;
						// }
						&.disabled {
							height: 60px;
							background: #f9f9f9;
							margin-top: 6px !important;
							cursor: not-allowed;
							pointer-events: auto !important;
						}

						.qadpt-sm-icon {
							min-width: 40px;
							color: #202224;
							img {
								filter: none;
							}
							&.active {
								color: #ffffff;
								img {
									filter: invert(30%) sepia(15%) saturate(75%) hue-rotate(45deg) contrast(100);
								}
							}
						}
						.cmg-soon {
							background: #d2e6ff;
							font-family: var(--font-family);
							font-size: 12px;
							line-height: 18px;
							color: #5c80ac;
							border-radius: 20px;
							padding-left: 8px;
							width: 100px;
						}
					}
				}
			}
		}
	}
	/*side-menu End */
	/* main content start*/
	.qadpt-settings-content {
		width: 100%;
		left: 240px;
		top: 45px;
		position: relative;
		&.qadpt-hide-smenu {
			top: auto !important;
			left: auto !important;
		}
		.qadpt-settings-page {
			display: flex;
			width: 100%;
			.MuiBox-root {
				width: 100%;
				& .qadpt-grd-action {
					svg {
						color: var(--button-bg-color) !important;
						cursor: pointer;
					}
				}
			}
			.usr-list-page {
				width: calc(100% - 410px);
				.MuiBox-root {
					padding: 20px 0 !important;
				}

				.qadpt-content-box.MuiBox-root {
					padding: 0 !important;
					&.qadpt-cdinst {
						height: calc(100vh - 200px);
						overflow: auto;
						.qadpt-stdata {
							font-size: 14px;
							width: calc(100% - 310px);
							.qadpt-steps {
								margin: 15px 0;
								.qadpt-subdata div {
									font-weight: 600;
								}
							}
						}
						.qadpt-codehdr {
							background-color: #f5f6fa;
							border-top-left-radius: 15px;
							padding: 9px;
							border-top-right-radius: 15px;
							width: 923px;
						}
						.qadpt-codehdrbt {
							background-color: #f5f6fa;
							border-bottom-left-radius: 15px;
							padding: 9px;
							border-bottom-right-radius: 15px;
							width: 923px;
							button {
								border: 1px solid #ccc;
								display: flex;
								align-items: center;
								padding: 9px 5px;
								border-radius: 15px;
								gap: 8px;
								cursor: pointer;
							}
							img {
								height: 20px;
							}
						}
						pre {
							margin: 0 !important;
							background: #000;
							color: #fff;
							padding-bottom: 15px;
							width: 923px;
						}
					}
				}
			}
			.css-1oqqzyl-MuiContainer-root {
				max-width: 100% !important;
			}
			.qadpt-settings-smenu {
				width: 180px;
				.MuiBox-root {
					padding: 0 !important;
				}
			}
		}
	}
	/* main content start*/
}

.qadpt-settings-container {
	display: flex;
	align-items: center;
	margin: 0 10px;
	.qadapt-link {
		margin: 0 10px;
		cursor: pointer;
	}
}

//app styles start
.App {
	text-align: center;
}
.bg-circles {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.circleOne,
.circleTwo,
.circleThree,
.circleFour,
.circleFive,
.circleSix {
	position: absolute;
	border-radius: 50%;
	border: 2px solid rgba(226, 225, 231, 0.877);
	background-color: transparent;
	z-index: 1;
}
.circleOne {
	right: -133px;
	top: 28%;
	transform: rotate(-90deg);
	height: 100px;
	width: 200px;
	border-radius: 150px 150px 0 0;
	z-index: 0;
}
.circleTwo {
	width: 40px;
	height: 40px;
	top: 160px;
	right: 209px;
}
.circleThree {
	height: 150px;
	width: 150px;
	top: -61px;
	right: 529px;
	z-index: 1;
}
.circleFour {
	height: 150px;
	width: 150px;
	top: -67px;
	left: 200px;
}
.circleFive {
	width: 50px;
	height: 50px;
	top: 168px;
	left: 135px;
}
.circleSix {
	width: 40px;
	height: 40px;
	left: 82px;
	bottom: 76px;
}
.from-DateTime {
	border-radius: 15px;
	height: 40px;
}

.lside-bgimg {
	height: calc(117vh - 193px);
	min-height: 300px;
	// background-image: url(/src/assets/icons/QuixyRegistrationfinalIllustration.png);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: -6% 85%;
	margin-top: 0px;
	max-width: -webkit-fill-available;
}

.guides {
	margin: 27px 36px;
}
.guides-text {
	font-size: 20px;
	font-weight: 600;
	height: fit-content;
	min-height: 36px;
	line-height: 36px;
	-webkit-box-pack: justify;
	justify-content: space-between;
	width: 100%;
	margin: 16px 0px;
}
.search-panel {
	background-color: rgb(244, 247, 253);
	padding: 20px;
	border-radius: 12px;
	margin-bottom: 24px;
}
.search-button {
	position: absolute;
	z-index: 99999;
	background-color: #007bff;
	border: none;
	border-radius: 5px;
	color: var(--white-color);
	cursor: pointer;
	font-size: 14px;
	padding: 7px 21px;
}
.search-block {
	width: fit-content;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 8px;
}
.search-label {
	width: 220px;
	height: 36px;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	gap: 8px;
	cursor: text;
	padding: 10px 16px;
	box-sizing: border-box;
	border-radius: 12px;
	overflow: hidden;
	border: 1px solid rgb(236, 240, 245);
	background-color: rgb(255, 255, 255);
	color: rgb(46, 65, 10);
}
.search-input {
	display: none;
	all: unset;
	width: 100%;
	height: 100%;
	font-size: 12px;
	line-height: 16px;
	font-weight: 500;
}
.filter-button {
	all: unset;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	height: fit-content;
	max-height: 36px;
	font-size: 14px;
	line-height: 24px;
	font-weight: 600;
	background-color: rgb(255, 255, 255);
	color: rgb(46, 65, 104);
	position: relative;
	max-width: 100px;
	width: 100px;
	border-radius: 12px;
	gap: 6px;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(236, 240, 245);
	border-image: initial;
	overflow: hidden;
	white-space: nowrap;
	padding: 8px 0px;
}
.filter-span {
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: none;
}
// .smooth-transition {
// 	transition: margin-left 0.3s ease-in-out;
// }

.open-button {
	padding: 0px;
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
	width: 23px;
	height: 50px;
	background-color: #999;
	color: var(--white-color);
	border: none;
	outline: none;
	cursor: pointer;
	z-index: 99999999;
	border-radius: 4px;
	transition: all 0.3s ease;
}
.sidebar.open {
	width: 14%;
	z-index: 99999;
}
.menu-items {
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: 4px;
	padding: 9px 18px;
	min-width: 150px;
	height: 21px;
}
.sidebar {
	display: flex;
	border-right: 5px solid #999999;
	background-color: var(--white-color);
	flex-direction: column;
	width: 0;
	height: 100%;
	color: var(--white-color);
	transition: width 0.3s ease;
	overflow: hidden;
	position: fixed;
	left: 0px;
	z-index: 99999999;
	margin-top: -1%;
}
.popup-title {
	display: flex;
	width: 100%;
	top: 0px;
	position: absolute;
}
.popup-desc {
	position: absolute;
	top: 30%;
	font-size: 16px !important;
	margin: 9px !important;
}
.popup-buttons {
	position: absolute;
	bottom: 9px;
	right: 0px;
}
.newguide-button {
	all: unset;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	height: fit-content;
	max-height: 36px;
	font-size: 14px;
	line-height: 24px;
	font-weight: 600;
	background-color: rgb(84, 120, 240);
	color: rgb(255, 255, 255);
	position: relative;
	max-width: 160px;
	width: 160px;
	border-radius: 12px;
	gap: 6px;
	border-width: 2px;
	border-style: solid;
	border-color: transparent;
	border-image: initial;
	overflow: hidden;
	white-space: nowrap;
	padding: 8px 0px;
	position: absolute;
	right: 36px;
}
.select-box {
	display: flex;
	flex-direction: row;
	margin-left: 24px;
	-webkit-box-align: center;
	align-items: center;
	height: 32px;
}
.select-svg {
	display: flex;
	flex-direction: row;
	-webkit-box-align: center;
	align-items: center;
}
.jvijPe {
	display: flex;
	flex-direction: row;
	-webkit-box-align: center;
	align-items: center;
	gap: 4px;
	width: fit-content;
	cursor: pointer;
}
.logout-div {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 18px 9px;
	width: 100%;
	/* position: fixed;
		bottom: 1px;
		left: 0; */
}
.select-panel {
	display: flex;
	flex-direction: row;
	gap: 8px;
	margin-left: 24px;
	-webkit-box-align: center;
	align-items: center;
}
.select-button {
	all: unset;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	max-height: 32px;
	font-size: 12px;
	line-height: 16px;
	font-weight: 600;
	height: 32px;
	width: 32px;
	background-color: transparent;
	color: rgb(116, 129, 155);
	position: relative;
	gap: 6px;
	border-radius: 8px;
	padding: 0px;
	border-width: initial;
	border-style: none;
	border-color: initial;
	border-image: initial;
	overflow: visible;
	white-space: nowrap;
}
.guide-table {
	width: 100%;
	cursor: pointer;
	padding: 16px 24px;
	background: rgb(255, 255, 255);
	border: 1px solid rgb(236, 240, 245);
	margin-top: 16px;
	box-sizing: border-box;
	box-shadow: rgba(55, 68, 115, 0.04) 0px 2px 2px;
	outline: none;
	border-radius: 16px;
	display: flex;
	flex-direction: row;
	-webkit-box-pack: justify;
	justify-content: space-between;
}
.guide-block {
	display: flex;
	flex-direction: row;
	-webkit-box-pack: justify;
	justify-content: space-between;
	width: 100%;
}
.checkbox-block {
	display: flex;
	flex-direction: row;
	gap: 12px;
	align-items: flex-start;
}

.updated-date {
	-webkit-box-align: center;
	align-items: center;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	color: rgb(46, 65, 104);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: none;
}
.date-text {
	margin-top: 8px;
	font-size: 12px;
	line-height: 16px;
	font-weight: 500;
	color: rgb(145, 157, 181);
	white-space: nowrap;
	user-select: none;
}
.inactive-block {
	font-size: 12px;
	line-height: 16px;
	font-weight: 500;
	display: flex;
	flex-direction: row;
	-webkit-box-align: center;
	align-items: center;
	box-sizing: border-box;
	height: fit-content;
	margin-top: 16px;
	gap: 16px;
}
.grey-bg {
	box-sizing: border-box;
	border-radius: 20px;
	display: flex;
	flex-direction: row;
	-webkit-box-align: center;
	align-items: center;
	width: fit-content;
	white-space: nowrap;
	background-color: rgb(236, 240, 245);
	height: 18px;
	padding-block: 0px;
	padding-inline: 12px 14px;
	border: none;
}
.vert-line {
	width: 1px;
	height: 18px;
	background: rgb(218, 225, 237);
	flex: 0 0 auto;
	-webkit-box-flex: 0;
}

.right-buttons {
	display: flex;
	flex-direction: row;
	-webkit-box-pack: end;
	justify-content: flex-end;
	padding: 0px;
	height: 32px;
	flex: 0 0 auto;
	order: 0;
	-webkit-box-flex: 0;
}
.right-icon {
	all: unset;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	max-height: 32px;
	font-size: 12px;
	line-height: 16px;
	font-weight: 600;
	height: 32px;
	width: 32px;
	background-color: transparent;
	color: rgb(116, 129, 155);
	position: relative;
	gap: 6px;
	border-radius: 8px;
	padding: 0px;
	border-width: initial;
	border-style: none;
	border-color: initial;
	border-image: initial;
	overflow: visible;
	white-space: nowrap;
}
.del-icon {
	all: unset;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	max-height: 32px;
	font-size: 12px;
	line-height: 16px;
	font-weight: 600;
	height: 32px;
	width: 32px;
	background-color: transparent;
	color: rgb(46, 65, 104);
	position: relative;
	gap: 6px;
	border-radius: 8px;
	padding: 0px;
	border-width: initial;
	border-style: none;
	border-color: initial;
	border-image: initial;
	overflow: visible;
	white-space: nowrap;
}
.default-text {
	max-width: 128px;
	font-size: 12px;
	line-height: 16px;
	font-weight: 500;
	align-self: end;
	color: rgb(145, 157, 181);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: none;
}
.svg-block {
	display: flex;
	flex-direction: row;
	-webkit-box-align: center;
	align-items: center;
}
.link-button {
	display: flex;
	flex-direction: column;
	-webkit-box-pack: justify;
	justify-content: space-between;
}
.edittag-button {
	all: unset;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	width: fit-content;
	height: fit-content;
	max-height: 32px;
	font-size: 12px;
	line-height: 16px;
	font-weight: 600;
	background-color: transparent;
	color: rgb(46, 65, 104);
	position: relative;
	padding: 8px 32px;
	gap: 6px;
	border-radius: 8px;
	border-width: initial;
	border-style: none;
	border-color: initial;
	border-image: initial;
	overflow: hidden;
	white-space: nowrap;
}
.del-button {
	all: unset;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	max-height: 32px;
	font-size: 12px;
	line-height: 16px;
	font-weight: 600;
	height: 32px;
	width: 32px;
	background-color: transparent;
	color: rgb(46, 65, 104);
	position: relative;
	gap: 6px;
	border-radius: 8px;
	padding: 0px;
	border-width: initial;
	border-style: none;
	border-color: initial;
	border-image: initial;
	overflow: visible;
	white-space: nowrap;
}

.layout {
	text-align: center;
	background: #5c6ac4;
	width: 100%;
	height: 92.3vh;
	position: absolute;
}
.child-layout button {
	background: rgb(48, 55, 59);
	height: 41px !important;
	margin-top: 1px;
	font-size: 15px;
}
.child-layout {
	width: 100%;
	margin: 0;
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.child-layout h2 {
	color: var(--white-color);
	font-size: 27px;
	padding: 9px;
}
.child-layout h1 {
	color: var(--white-color);
	font-size: 40px;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.userButton {
	position: absolute;
	right: 47px;
	z-index: 99;
	top: 519px;
	background-color: #007bff;
	border: none;
	border-radius: 5px;
	color: var(--white-color);
	cursor: pointer;
	font-size: 14px;
	padding: 7px 21px;
}

.closeusercreate-icon {
	position: absolute;
	top: 13px;
	right: 0px;
	fill: #ffffff;
	cursor: pointer;
	right: 5px;
}
.css-1rtad1 {
	display: block !important;
}

.userEdit-popup h1 {
	background-image: linear-gradient(to right, rgba(37, 81, 181, 1), rgba(0, 0, 0, 0.7));
	font-size: 17px;
	color: var(--white-color);
	text-align: left;
	height: 44px;
	padding: 10px 0 0 20px;
	margin: 0 -20px 14px;
}
.switch {
	position: absolute;
	right: 17px;
	display: inline-block;
	width: 54px;
	height: 27px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

// .slider {
// 	position: absolute;
// 	cursor: pointer;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	background-color: #ccc;
// 	-webkit-transition: 0.4s;
// 	transition: 0.4s;
// }
.container {
	flex: 1;
	justify-content: center;
	align-items: center;
	margin-top: 11px;
	margin-left: -3px;
}

.radioButton input[type="radio"] {
	margin-top: 7px;
}
.radioGroup {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}
.radioButton {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.radioLabel {
	margin-left: 8px;
	font-size: 17px;
	color: #333;
}

// .slider:before {
// 	position: absolute;
// 	content: "";
// 	height: 21px;
// 	width: 21px;
// 	left: 4px;
// 	bottom: 3px;
// 	background-color: var(--white-color);
// 	-webkit-transition: 0.4s;
// 	transition: 0.4s;
// }

// input:checked + .slider {
// 	background-color: #2196f3;
// }

// input:focus + .slider {
// 	box-shadow: 0 0 1px #2196f3;
// }

// input:checked + .slider:before {
// 	-webkit-transform: translateX(26px);
// 	-ms-transform: translateX(26px);
// 	transform: translateX(26px);
// }

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
.user-popup {
	//overflow-y: scroll;
	z-index: 99999;
	width: 400px !important;
	height: 100%;
	top: 46px;
	right: 0;
	position: fixed !important;
	display: block !important;
	background: var(--white-color) !important;
	box-shadow: 0 3px 8px #000000;
	.qadpt-header {
		background-image: linear-gradient(to right, rgb(37, 81, 181), rgba(0, 0, 0, 0.7));
		padding: 10px;
		span {
			font-size: 16px;
			color: var(--white-color);
			margin: 0;
		}
	}
	.close-icon {
		position: absolute;
		top: 11px;
		right: 10px;
		fill: var(--white-color);
		cursor: pointer;
		height: 16px;
		width: 16px;
	}
	.qadpt-usrform {
		padding: 20px;
		height: calc(100% - 175px);
		overflow-y: auto;
		.qadpt-txtinp {
			width: 100%;
			font-size: 14px;
			border: 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
			padding: 10px 0;
			margin: 0;
		}
		.qadpt-txtfld {
			margin-bottom: 10px;
			&.qadpt-switch {
				display: flex;
				align-items: center;
				justify-content: space-between;
				.MuiFormControlLabel-root {
					margin: 0 !important;
				}
			}
		}
		.qadpt-txtlabel {
			font-size: 14px;
		}
	}

	.error-input {
		border: 1px solid var(--error-color);
	}
	.qadpt-button {
		text-align: center;
		width: 100%;
		button {
			color: var(--white-color);
			border-radius: 4px;
			border: none;
		}
		.qadpt-disab {
			background-color: #ccc;
			border: none;
			padding: 6px 14px;
			font-size: 16px;
			cursor: not-allowed;
		}
		.qadpt-enab {
			background-color: var(--button-bg-color);
			padding: 6px 14px;
		}
	}
}

.userAccount-popup {
	/* overflow-y: scroll;
	 z-index: 99999; */
	z-index: 100;
	width: 300px !important;
	height: 91vh;
	top: 51px;
	right: 0;
	padding: 0 20px;
	position: fixed !important;
	display: block !important;
	background: var(--white-color) !important;
	box-shadow: 0 3px 8px #000000;
}

.MuiPickersPopper-container {
	z-index: 100000; /* Adjust as needed */
}
.userDelete-popup {
	z-index: 99999;
	width: 214px;
	height: 217px;
	top: 235px;
	margin-top: 9px;
	right: 0;
	padding: 0 20px;
	background: var(--white-color) !important;
	box-shadow: 0 3px 8px #000000;
	margin-right: 500px;
}

.filter-options {
	display: flex;
	margin-left: 27px;
}
.filter-options input,
select {
	margin-top: 16px;
	background: transparent;
	color: #000000de;
	box-shadow: none;
	position: relative;
	width: 100%;
	font-family: var(--font-family);
	line-height: 1.4;
	margin-bottom: 4px;
	border-radius: 0;
	font-size: 14px;
	border: 0;
	min-height: 22px !important;
	//border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
	min-width: 0;
	padding: 4px 0;
	line-height: 19.6px;
	//margin-bottom: 4px;
}
.searchInput {
	color: #000000de;
	box-shadow: none;
	position: relative;
	width: 30%;
	font-family: var(--font-family);
	border-radius: 5px;
	font-size: 16px;
	border: 0;
	min-height: 36px !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
	min-width: 0;
	padding: 4px 10px;
	line-height: 19.6px;
	margin-right: 4px;
}
.searchInput:focus {
	outline: none;
}
.user-popup input:focus,
select:focus {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	outline: none;
	border-bottom: 2px solid rgba(189, 28, 28, 0.527) !important;
}

/* Start Settings Styles */
.settings-div {
	padding-left: 2rem;
	padding-right: 2rem;
	padding-bottom: 2.5rem;
	// padding-top: 1.5rem;
}
.settings-header {
	justify-content: left;
	font-size: 1.875rem;
	line-height: 2.25rem;
	font-weight: 500;
	letter-spacing: -0.025em;
	color: #0f172a;
}
.settings-nav {
	margin-top: 1.25rem;
	position: relative;
}

.settings-ul {
	margin-left: -30px;
	padding-bottom: 1.25rem;
	overflow-x: auto;
	gap: 0.75rem;
	margin-bottom: 1rem;
	list-style-type: none;
	display: contents;
	width: 1109px;
}

.settings-ul {
	display: flex;
	/* flex-direction: column; */
}

// .settings-ul.rtl-list {
// 	/* flex-direction: column-reverse; */
// }

.settings-li {
	opacity: 1;
	border-color: rgb(226 232 240);
	border-width: 1px;
	border-radius: 10px;
	height: 25px;
	border-style: solid;
	float: left;
	padding: 4px;
	font-size: 13px;
	margin-right: 12px;
	width: 115px;
}
.settings-li:hover {
	border: 1px solid black;
}
.settings-anchor {
	color: rgb(100 116 139);
	line-height: 0.625rem;
	padding-left: -0.375rem;
	padding-right: 0.625rem;
	height: 28px;
	background-color: transparent;
	text-decoration: none;
}
.settings-svg {
	margin-right: 0.5rem;
	color: rgb(107 114 128);
	vertical-align: middle;
	overflow: hidden;
	fill: rgb(107 114 128);
	margin-top: -3px;
}
.invite-block {
	padding: 1.5rem;
	border-color: rgb(226 232 240);
	border-style: solid;
	border-bottom-width: 1px;
	border-left: none;
	border-right: none;
	border-top: none;
	justify-content: space-between;
	align-items: center;
	display: flex;
}
.invite-items {
	display: flex;
	align-items: center;
}
.invite-svg {
	padding: 0.5rem;
	fill: rgb(30 41 59);
	background-color: rgb(241 245 249);
	border-radius: 0.5rem;
	margin-right: 0.75rem;
	vertical-align: middle;
	overflow: hidden;
	height: 25px;
	width: 25px;
}
.team-settings {
	padding: 2rem 2rem 0rem 2rem;
}
.qx-align {
	display: flex;
	align-items: center;
	padding: 1.5rem;
}
.qx-border {
	border-color: rgb(226 232 240);
	border-style: solid;
	border-width: 1px;
	border-radius: 0.75rem;
}
.qx-border-bottom {
	border-color: rgb(226 232 240);
	border-style: solid;
	border-width: 0px;
	border-bottom-width: 1px;
	border-radius: 0.75rem;
}
.team-ul {
	padding: 1rem;
	border-radius: 0;
	display: flex;
	flex-direction: column;
	margin-bottom: 0;
	list-style: none;
	margin-top: 0;
}
.team-li {
	border-width: 0 0 1px;
	padding: 1rem 0rem;
	padding-right: 0 !important;
	background-color: var(--white-color);
	border-bottom: 1px solid rgba(11, 24, 21, 0.125);
	display: block;
	padding-top: 1rem !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.team-div {
	width: 100%;
	align-items: center !important;
	display: flex !important;
	margin-bottom: 0 !important;
}

.qadpt-prof {
	border-radius: 50% !important;
	margin-right: 0.5rem !important;
	.prof-div {
		cursor: pointer;
		font-size: 16px;
		width: 34px;
		height: 34px;
		font-weight: 700;
		background-color: rgb(201 190 253);
		border-color: rgb(255 255 255);
		border-width: 2px;
		border-radius: 50%;
		overflow: hidden;
		display: flex;
	}
	.prof-span {
		display: inline-block;
		margin: auto;
	}
}
.team-involve {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-bottom: 1rem;
	padding-top: 1rem;
}
.qx-flex {
	display: flex;
}
.text-span {
	font-size: 0.75rem;
	line-height: 1rem;
	padding-bottom: 0.125rem;
	padding-top: 0.125rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	border-radius: 0.75rem;
	align-items: center;
	user-select: none;
	display: inline-flex;
	background-color: rgb(241 245 249);
}
.mail-h6 {
	line-height: 0.9rem;
	font-size: 0.8rem;
	font-weight: 400 !important;
	color: #0f172a;
	margin-bottom: 0.5rem;
	margin-top: 0;
}
.qx-role {
	min-width: 150px;
	margin-right: 0.75rem;
}

.role-span {
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.25rem;
	text-overflow: ellipsis;
	width: 100%;
	display: flex;
	margin-bottom: auto;
	margin-top: auto;
}
.qx-items-center {
	align-items: center;
}
.qx-w-full {
	width: 100% !important;
}
.qx-flex {
	display: flex;
}
.email-input {
	align-items: center;
	width: 100% !important;
	display: flex;
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;
	background-color: rgb(255 255 255);
	border-color: rgb(203 213 225);
	border-width: 1px;
	border-bottom-left-radius: 0.5rem;
	border-top-left-radius: 0.5rem;
	border-style: solid;
	overflow: visible;
	line-height: 1.25rem;
}
.complete-block {
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	border-color: rgb(226 232 240);
	border-style: solid;
	border-width: 1px;
	border-radius: 0.75rem;
}
.invite-button {
	opacity: 0.7;
	text-decoration-line: none !important;
	line-height: 1.25rem;
	font-weight: 600 !important;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;
	background-color: rgb(255 255 255);
	border-width: 1px;
	border-left-width: 0 !important;
	border-bottom-right-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
	border-color: rgb(203 213 225);
	border-style: solid;
}
.qx-relative {
	position: relative;
}
.role-div {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	flex: 1 1;
}
.qx-button {
	box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000;
	text-decoration-line: none !important;
	color: rgb(30 41 59);
	font-size: 0.875rem;
	vertical-align: middle;
	text-align: left;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;
	background-color: rgb(255 255 255);
	line-height: 1.25rem;
	border-style: solid;
	border-width: 1px;
	border-radius: 0.375rem;
	justify-content: center;
	align-items: center !important;
	cursor: pointer !important;
	max-width: 280px;
	width: 100%;
	border-color: rgb(226 232 240);
}
.team-involve-text {
	color: rgb(51 65 85);
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1.25rem;
	margin-bottom: 0.5rem;
	margin-top: 0;
}
.invite-text {
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 700;
	color: #0f172a;
}
.role-access {
	user-select: none;
	cursor: default;
	height: fit-content;
	display: inline-flex;
}
.unlimited-seats {
	padding-left: 0.625rem;
	padding-right: 0.625rem;
	background-color: rgb(241 245 249);
	border-color: rgb(226 232 240);
	border-style: solid;
	border-width: 1px;
	border-bottom-left-radius: 0.375rem;
	border-top-left-radius: 0.375rem;
	align-items: center;
	height: 2.5rem;
	display: flex;
}
.unlimited-div {
	padding-left: 0.875rem;
	padding-right: 0.875rem;
	border-color: rgb(226 232 240);
	border-style: solid;
	border-right-width: 1px;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-bottom-right-radius: 0.375rem;
	border-top-right-radius: 0.375rem;
	align-items: center;
	height: 2.5rem;
	display: inline-flex;
}
/* End Settings Styles */

/* Profile Settings Page*/
.profile-text {
	margin-left: 36px;
	display: flex;
	align-items: center;
	margin-top: 13px;
}
.class-labels {
	margin-left: 36px;
	margin-top: 27px;
	display: flex;
	align-items: center;
	/* color: #595d85; */
	font-weight: 500;
	font-size: 15px;
}
.text-field {
	margin-left: 36px;
	padding: 8px 10px;
	border-radius: 9px;
	font-size: 14px;
	border: 1px solid rgb(204, 204, 204);
	text-align: start;
	width: 23%;
	margin-top: 10px;
	background-color: #f4f7fd;
	color: #595d85;
	outline: none;
	box-shadow: none;
}

.email-change-button {
	cursor: pointer;
	font-size: 13px;
	padding: 7px 21px;
	background: transparent;
	color: #5d748c;
	border: 2px solid #e5e5e5;
	border-radius: 10px;
	margin-top: 18px;
	margin-left: 36px;
	background-color: #f4f7fd;
	font-weight: bold;
	display: flex;
}
.password-change-button {
	padding: 7px 9px;
	cursor: pointer;
	font-size: 13px;
	background: transparent;
	color: #0f172b;
	border: 2px solid #e5e5e5;
	border-radius: 10px;
	background-color: #f4f7fd;
	font-weight: bold;
	color: #595d85;
	margin-left: 36px;
	margin-top: 18px;
	display: flex;
}
.authentication-switch {
	position: absolute;
	left: 119px;
	display: inline-block;
	width: 54px;
	height: 27px;
	margin-top: 10px;
	color: #595d85;
}
.cancel-button {
	margin-left: 1036px;
	margin-top: -42px;
	border-radius: 10px;
	padding: 7px 38px;
	position: absolute;
	z-index: 99999;
	cursor: pointer;
	font-size: 14px;
	background: transparent;
	background-color: #f4f7fd;
	color: black;
	border: 0px solid #e5e5e5;
	font-weight: bold;
}
.save-button {
	position: absolute;
	z-index: 99999;
	cursor: pointer;
	font-size: 14px;
	background: transparent;
	background-color: blue;
	color: var(--white-color);
	font-weight: bold;
	border: 0px solid #e5e5e5;
	margin-left: 1185px;
	margin-top: -42px;
	border-radius: 10px;
	padding: 7px 42px;
}
.disabled-text {
	margin-left: 98px;
	color: #595d85;
	font-family: sans-serif;
	font-size: 14px;
}
/* Profile Settings Page*/

/* Account Settings Page */
.account-block {
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	border-color: rgb(226 232 240);
	border-style: solid;
	border-width: 1px;
	border-radius: 0.75rem;
	height: 120px;
	width: 90%;
	margin-left: 35px;
	margin-top: 27px;
}

.receive-text {
	color: rgb(100, 116, 139);
	font-size: 13px;
	margin-left: 19px;
	margin-top: 5px;
	text-decoration: none;
}
.tooltip:hover .editbtn-tooltip {
	visibility: visible;
}
.tooltip .editbtn-tooltip::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #334155 transparent transparent transparent;
}
.tooltip .editbtn-tooltip {
	visibility: hidden;
	width: 203px;
	background-color: #334155;
	color: var(--white-color);
	text-align: left;
	border-radius: 4px;
	padding: 9px;
	margin-left: -196px;
	margin-top: -54px;
	position: absolute;
	z-index: 1;
	font-size: 13px;
}
/* Account Settings Page*/

/* Right Settings Page*/

.right-authentication-block {
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	border-color: rgb(226 232 240);
	border-style: solid;
	border-width: 1px;
	border-radius: 0.75rem;
	height: 129px;
	width: 958px;
	margin-left: 49px;
	margin-top: 27px;
}
.right-setting-header {
	margin-bottom: 18px;
	margin-left: 33px;
	font-size: 14px;
	color: "#0F172B";
	font-family: var(--font-family);
	font-size: 14px;
}
.user-authentication-text {
	box-sizing: border-box;
	color: rgb(51 65 85);
	display: block;
	font-size: 14px;
	height: 60px;
	line-height: 1.25rem;
	text-size-adjust: 100%;
	width: 947px;
	margin-left: 18px;
	font-family: var(--font-family);
}
.qxy-content-block {
	max-height: calc(100vh - 220px);
	overflow-y: scroll;
	margin-top: -30px;
	.qadpt-sub-title {
		padding: 0;
	}
	.qadpt-box {
		padding-bottom: 1rem;
		margin-bottom: 1rem;
		border: 1px solid var(--border-color);
		border-radius: 6px;
		margin-top: 15px;
	}

	.right-setting-text {
		justify-content: space-around;
		flex-direction: column;
		display: flex;
		color: #0f172b;
	}

	.qadpt-swtch-label {
		margin-top: 20px;
		padding: 0 15px;
		font-weight: 600;
	}

	.qadpt-box-desc {
		margin: 15px;
	}
	.qadpt-box-switch {
		display: flex;
		float: right;
		position: relative;
		right: 10px;
	}
	.right-relative {
		position: relative;
		--tw-border-opacity: 1;
		right: -650%;
		margin-top: -47px;
	}
}
.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 203px;
	background-color: var(--tool-tip-bg);
	color: var(--white-color);
	text-align: left;
	border-radius: 4px;
	padding: 10px;
	margin-left: 822px;
	margin-top: -80px;
	position: absolute;
	z-index: 1;
	font-size: 12px;
}
.tooltip:hover .tooltiptext {
	visibility: visible;
}

.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #334155 transparent transparent transparent;
}

.cursor-not-allowed {
	cursor: not-allowed;
	margin-top: -31px;
	margin-left: 985px;
}
.editbtn-cursor-not-allowed {
	cursor: not-allowed;
}
.pointer-events {
	pointer-events: none;
}
.label {
	align-items: center;
	-webkit-user-select: none;
	user-select: none;
	cursor: not-allowed;
	display: flex;
	margin-bottom: 0;
	position: relative;
}

.switch-textfield {
	box-sizing: border-box;
	padding: 11px;
	opacity: 0;
	width: 86%;
	height: 100%;
	pointer-events: none;
	position: absolute;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	margin: 0;
	overflow: visible;
	-webkit-user-select: none;
	user-select: none;
}

.switch-spanone {
	width: 42px;
	height: 24px;
	transition-duration: 0.25s;
	--tw-bg-opacity: 1;
	background-color: rgb(203 213 225 / var(--tw-bg-opacity));
	border-radius: 0.75rem;
	justify-content: space-between;
	align-items: center;
	display: inline-flex;
	position: relative;
}

.switch-spantwo {
	width: 15px;
	height: 15px;
	left: 10px;
	transition-duration: 0.25s;
	transition-property: inherit;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	--tw-bg-opacity: 1;
	background-color: rgb(255 255 255 / var(--tw-bg-opacity));
	border-radius: 10156px;
	justify-content: center;
	align-items: center;
	--tw-translate-y: -61%;
	display: flex;
	z-index: 21;
	top: 50%;
	cursor: not-allowed;
	pointer-events: none;
	-webkit-user-select: none;
	user-select: none;
	margin-left: 4px;
}

.switch-spanthree {
	--tw-text-opacity: 1;
	color: rgb(220 225 224 / var(--tw-text-opacity));
	text-transform: uppercase;
	font-weight: 700;
	font-size: 7px;
	font-family: var(--font-family);
	margin-left: -0.625rem;
	cursor: not-allowed;
	pointer-events: none;
	-webkit-user-select: none;
	user-select: none;
}
.switch-spanfour {
	--tw-text-opacity: 1;
	color: rgb(71 85 105 / var(--tw-text-opacity));
	text-transform: uppercase;
	font-weight: 700;
	font-size: 8px;
	margin-right: 5px;
	box-sizing: border-box;
	cursor: not-allowed;
	pointer-events: none;
	-webkit-user-select: none;
	user-select: none;
	text-align: left;
	line-height: 7.25rem;
}
.switch-spanfive {
	--tw-text-opacity: 1;
	color: rgb(148 163 184 / var(--tw-text-opacity));
	fill: currentColor;
	overflow: hidden;
	vertical-align: middle;
	box-sizing: border-box;
	height: 27px;
	width: 10px;
	--tw-bg-opacity: 1;
	color: rgb(148, 163, 184);
	fill: rgb(148, 163, 184);
	font-size: 14px;
	font-weight: 800;
	height: 17px;
	line-height: 20px;
	overflow-clip-margin: content-box;
	overflow-x: hidden;
	overflow-y: hidden;
}
.right-tooltip {
	width: 100%;
	display: inline-block;
	box-sizing: border-box;
	--tw-text-opacity: 1;
}
/* Right Settings Page*/

.organization-dropdown {
	box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000;
	text-decoration-line: none !important;
	color: rgb(30 41 59);
	font-size: 0.875rem;
	vertical-align: middle;
	text-align: left;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	padding-bottom: 0.5rem;
	padding-top: -0.5rem;
	background-color: rgb(255 255 255);
	line-height: 1.25rem;
	border-style: solid;
	border-width: 1px;
	border-radius: 0.375rem;
	justify-content: center;
	align-items: center !important;
	cursor: pointer !important;
	max-width: 280px;
	width: 10%;
	border-color: black;
	margin-left: 1198px;
	height: 27px;
	margin-top: -37px;
}
.org-text {
	margin-top: -30px;
	font-size: 14px;
	margin-left: 1183px;
	margin-bottom: 20px;
}
.dropdown-options {
	width: 11%;
	margin-left: 1171px;
	margin-top: -34px;
}

/*
.addadmin
{
	margin-top: 24px;
    height: 309px;
    width: 602px;
    border: 3px solid rgb(241 245 249);
    text-align: center;
    margin-left: 393px;
} */
// .addadmin {
// 	margin-top: 24px;
// 	height: 416px; /* Default height */
// 	width: 602px;
// 	border: 3px solid rgb(241, 245, 249);
// 	text-align: center;
// 	margin-left: 393px;
// 	transition: height 0.3s ease; /* Optional: Add smooth transition */
// }

// .addadmin.has-errors {
// 	height: 438px; /* Adjusted height when hasErrors is true */
// }

// .addadmin-text {
// 	height: 25px;
// 	width: 540px;
// 	margin-left: -15px;
// 	margin-top: 16px;
// }
// .addadmin-button {
// 	display: flex;
// 	border: 1px solid grey;
// 	margin-left: 217px;
// 	margin-top: 46px;
// 	padding: 6px 10px;
// }
.qadpt-addadmin {
	position: relative;
	top: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	place-content: center;
	.qadpt-block {
		background: var(--white-color);
		border: 1px solid #ccc;
		padding: 20px;
		border-radius: 4px;
		box-shadow: 0 1px 10px 1px #00000033, 0 0 1px #00000024, 0 1px 3px #0000001f;
		text-align: center;
	}
	.addadmin-heading {
		font-weight: 600;
		font-size: 21px;
		padding: 10px;
	}
	.qadpt-adminfld {
		padding: 10px;
		.MuiInputLabel-shrink {
			top: 7px !important;
		}
		.MuiFormControl-root {
			height: 45px;
			width: 450px;
		}
		.Mui-error {
			margin: 3px 0 0 0;
		}
	}
	.qadpt-subbtn {
		background-color: var(--button-bg-color);
		color: var(--white-color);
		width: 70px;
		height: 31px;
		border: none;
		border-radius: 4px;
	}
}

.userDelete-popup {
	overflow-y: scroll;
	z-index: 99999;
	width: 200px !important;
	height: 20vh;
	top: 243px;
	right: 0;
	padding: 0 20px;
	position: fixed !important;
	display: table !important;
	background: var(--white-color) !important;
	box-shadow: 0 3px 8px #000000;
}

.error {
	color: var(--error-color); /* Red color for error message */
	font-size: 0.8em; /* Adjust font size for error message */
	margin-top: 5px; /* Margin to separate error message from input */
}

.form-group {
	margin-bottom: 20px; /* Space between form groups */
}

/* Optional: Highlight input field in red when there's an error */
.error-input:focus {
	outline-color: var(--error-color); /* Red outline when input is focused and there's an error */
}

.userButton.button-disabled {
	background-color: #ccc;
	color: #888;
	cursor: not-allowed;
}
.input-container {
	position: relative;
	margin-bottom: 20px;
}

.input-label {
	position: absolute;
	left: 10px;
	top: 10px;
	transition: all 0.3s ease-in-out;
	pointer-events: none;
	color: #666;
}

.float {
	top: -10px;
	font-size: 12px;
	color: #333;
}
.admin-input {
	width: 449px;
	height: 40px;
	padding: 10px;
	font-size: 16px;
	border: 1px solid #ccc;
	transition: all 0.3s ease-in-out;
}

.admin-input:focus {
	border-color: #007bff; /* Change border color on focus */
	outline: none;
}

.multiselect {
	margin-left: 302px;
	margin-top: -49px;
	width: 204px;
	height: 65px;
}

.add-dropdown {
	width: 192px;
	margin-left: 242px;
	margin-top: -10px;
	height: 37px;
}
.add-button {
	margin-top: -40px;
	margin-left: 462px;
}
.bold-header {
	font-weight: 1050;
	font-size: 20px;
}
/* .add-button
  {
	margin-top: -46px;
    margin-left: 450px;
    border: 1px solid black;
    border-radius: 5px;
    width: 72px;
  } */
.updatebutton-container {
	float: left;
	margin-left: -2%;
	margin-top: 29%;
}
.updateButton {
	font-size: 14px;
	padding: 7px 21px;
	border: none;
	background-color: #007bff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
	transition: background-color 0.3s, color 0.3s;
}

/* Styles for enabled update button */
.updateButton--enabled {
	background-color: #007bff; /* Blue color */
	color: var(--white-color); /* White text */
}

/* Styles for disabled update button */
.updateButton--disabled {
	background-color: #e0e0e0; /* Light grey */
	color: #b0b0b0; /* Grey text */
	cursor: not-allowed;
}

.goog-te-banner-frame.skiptranslate {
	display: none !important;
}

/* App.css */
.skiptranslate .goog-te-banner-frame {
	display: none !important;
}
/* App.css */
.goog-te-gadget-simple .goog-te-gadget-icon,
.goog-te-gadget-simple .goog-te-gadget-simple {
	display: none;
}
#goog-gt-tt {
	display: none; /* Hide the element */
}
.google-translate-powered-by {
	display: none !important; /* Use !important to override inline styles */
}
.goog-te-gadget-simple {
	display: none;
}
/* Hide "Powered by Google Translate" attribution */
.goog-logo-link {
	display: none !important;
}
.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
body,
html,
#root {
	overflow: hidden;
	height: 100%;
	margin: 0;
	padding: 0;
}
//users

.qadpt-prfsidebar {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 55px;

	&.open {
		margin-left: 295px;
	}

	&.closed {
		margin-left: 3%;
	}

	.qadpt-prftitle {
		font-size: 20px !important;
		font-weight: 600 !important;
		line-height: 48px !important;
		letter-spacing: -0.114px !important;
		text-align: left;
		margin-right: auto !important;
	}

	.qadpt-prfbtn {
		cursor: pointer !important;
		color: var(--white-color) !important;
		font-weight: 500 !important;
		border: 0px solid #e5e5e5 !important;
		border-radius: 15px !important;
		padding: 6px 18px !important;
		text-transform: none !important;
		margin: 0 -50px 0 0px;
	}
	.qadpt-prfbtn-disabled {
		background-color: #a5c3c5 !important;
		cursor: not-allowed !important;
	}

	.qadpt-prfbtn-enabled {
		background-color: rgba(95, 158, 160, 1) !important;
	}
}

.qadpt-file-error {
	color: var(--error-color);
	position: absolute; // Position it absolutely
	bottom: -20px; // Adjust the position below the box
	left: 81%; // Center the error
	transform: translateX(-50%); // Correct centering
	white-space: nowrap;
	text-overflow: ellipsis;
}
.qadpt-crossIcon {
	position: absolute;
	top: -34px;
	left: 410px;
	width: 16px;
	height: 16px;
	color: rgba(175, 175, 175, 1);
}
.qadpt-prfbox {
	margin-top: 18px;
	background: var(--white-color);
	border-radius: 8px;
	padding: 10px 20px;
	box-sizing: border-box;
	height: calc(100vh - 139px);
	overflow-y: auto;
	min-height: calc(90vh - 90px);

	&.open {
		margin-left: 20%;
		width: calc(88% - 60px);
	}

	&.closed {
		margin-left: 3%;
		width: calc(100% - 0px);
	}

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(95, 158, 160, 0.8);
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: rgba(95, 158, 160, 1);
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	.qadpt-subtle {
		position: sticky;
		top: -20px;
		z-index: 1;
		background: var(--white-color);
		padding: 14px 18px;
		margin-left: -10px;
		margin-right: -20px;
		font-weight: 600;
		font-size: 16px;
	}
	.qadpt-prfimgcon {
		width: 90px;
		height: 90px;
		position: relative;
		top: 8px;
		left: -84px;
		background: rgba(246, 238, 238, 1);
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;

		.qadpt-sltimg {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			object-fit: cover;
		}

		.qadpt-prfimg {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			object-fit: cover;
			opacity: 0.5;
		}

		.qadpt-editcont {
			position: absolute;
			bottom: 10px;
			right: -10px;
			background-color: rgba(24, 20, 243, 1);
			padding: 5px;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
			width: 25px;
			height: 25px;

			.qadpt-editicon {
				color: var(--white-color);
				width: 15px;
				height: 15px;
			}
		}
	}

	.qadpt-txtctrl {
		position: relative;
		width: 100%;
		font-weight: 500;
		color: rgba(34, 34, 34, 1);
		padding: 0 4px;
		font-size: 14px;
		display: flex;
		flex-direction: column;
		font-weight: 600;
		.qadpt-prffldval {
			width: 365.67px;
			height: 45px;
			border-radius: 6px;
			border: 1px solid rgba(220, 221, 225, 1) !important;
			opacity: 0.9;
			margin-top: 5px;

			.MuiOutlinedInput-root {
				fieldset {
					border-color: rgba(220, 221, 225, 0.1) !important;
				}

				&:hover fieldset {
					border-color: rgba(220, 221, 225, 0.1) !important;
				}

				&.Mui-focused fieldset {
					border-color: rgba(220, 221, 225, 0.1) !important;
				}

				input {
					padding: 11px 12px;
					font-family: var(--font-family);
					font-size: 16px;
					font-weight: 400;
					line-height: 28px;
					text-align: left;
				}
			}
		}
	}

	.qadpt-prfchgpwd {
		margin: 10px 110px !important;
		border-radius: 15px !important;
		border: 1px solid transparent !important;
		border-color: rgba(95, 158, 160, 1) !important;
		color: rgb(95, 158, 160);
		font-weight: 600 !important;
		text-transform: none;
	}
}

.css-1gcrhl3 {
	box-shadow: none !important;
}
.grid-toolbar-options {
	width: 100% !important;
	margin: 20px 0 0;
	.left-options {
		width: 100%;
		max-width: 100%;
		display: flex;
		.drp-fields,
		.dt-fields {
			display: flex;
			margin-right: 15px;
			.MuiInputLabel-outlined.MuiInputLabel-shrink {
				top: 7px !important;
			}
			.auto-filed,
			.qadpt-DateTime {
				width: 50%;
				max-width: 50%;
				min-width: 50%;
				margin: 0 5px;
			}
			button {
				text-transform: unset !important;
			}
		}
		.drp-fields {
			.MuiInputBase-root.MuiOutlinedInput-root {
				padding-right: 35px;
				.MuiAutocomplete-input {
					width: inherit !important;
					min-width: 30px;
					max-width: 100%;
				}
			}
		}
		.auto-filed,
		.name-fld,
		.qadpt-DateTime {
			.MuiSvgIcon-root {
				height: 16px !important;
				width: 16px !important;
			}
			&.dt-fld2 {
				margin-left: 0px;
				&.hide-close {
					margin-left: 0;
				}
			}
			input {
				font-size: 12px !important;
				padding-right: 0 !important;
			}
		}
		.dt-close-icon {
			right: 55px;
			height: 40px;
		}
	}

	.right-options {
		display: flex;
		margin: 5px;
		place-content: flex-end;
		button {
			text-transform: unset !important;
			padding: 5px;
			height: 32px;
			border-radius: 20px;
			margin: 0 0 0 5px;
		}
	}
	svg {
		height: 16px;
		width: 16px;
	}
	.name-fld {
		.MuiInputLabel-outlined.MuiInputLabel-shrink {
			top: 7px !important;
		}
	}
}
[class*="MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input"] {
	padding: 10px !important;
}
[class*="MuiInputBase-input"] {
	padding: 10px !important;
	height: 20px !important;
	font-size: var(--font-size) !important;
}
[class*="MuiOutlinedInput-notchedOutline"],
[class*="MuiInputBase-root-MuiOutlinedInput-root"] {
	top: 0 !important;
	// border-radius: 20px !important;
	font-size: var(--font-size) !important;
}
[class*="MuiInputLabel-formControl"] {
	font-size: var(--font-size) !important;
}
[class*="MuiTooltip-tooltip"] {
	margin-bottom: 0 !important;
}
[class*="MuiFormHelperText-root Mui-error"] {
	margin: 0 !important;
	line-height: 12px !important;
	display: flex;
	align-items: center;
}
.MuiInputBase-input:-webkit-autofill {
	background-color: white !important;
	box-shadow: 0 0 0px 1000px white inset !important;
	color: #000 !important;
}
.qadpt-orgcont {
	position: relative;
	left: 20px;
	top: 35px;
	width: calc(100% - 40px);
	.MuiContainer-root {
		padding: 0 !important;
	}
}
.qadpt-page-content .qadpt-settings-content .usr-list-page {
	.qadpt-usercreatepopup,
	.qadpt-userEditpopup {
		.qadpt-formcontent {
			padding: 10px 15px !important;
			.qadpt-userfields .MuiOutlinedInput-notchedOutline {
				border-radius: 5px !important;
			}
		}
	}
}
.qadpt-btn-default {
	width: 100%;
	background-color: rgba(95, 158, 160, 1) !important;
	color: white !important;
	padding: 10px !important;
	border-radius: 15px !important;
	font-size: 14px !important;
	margin-top: 30px !important;
	text-transform: capitalize !important;
	&:hover {
		background-color: rgba(95, 158, 160, 1) !important;
		color: white !important;
	}
}
.qadpt-closeicon {
	position: absolute;
	top: 10px;
	cursor: pointer;
	right: 15px;
	width: 15px;
}
.qadpt-toaster {
	top: 30px !important;
	width: 40%;
	z-index: 999999 !important;
	.MuiAlert-message {
		display: block;
		overflow: hidden !important;
		word-break: break-word;
	}
	&.qadpt-toaster-success {
		border: 1px solid #2e7d32;
	}
	&.qadpt-toaster-error {
		border: 1px solid #ff0000;
	}
	.qadpt-alert {
		width: 150%;
	}
}

.MuiPopper-root.MuiAutocomplete-popper .MuiAutocomplete-paper {
	font-size: 14px;
}

.qadpt-usrconfirm-popup {
	width: 400px;
	max-height: 300px;
	top: 186px;
	right: 480px;
	// padding: 10px;
	position: fixed !important;
	background: var(--white-color) !important;
	border-radius: 4px;
	.qadpt-usrconfirm-popup div {
		display: flex;
		align-items: center;
		place-content: center;
	}
	> div:first-child {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
	}
	.qadpt-icon {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		i {
			color: var(--error-color);
			font-size: 20px !important;
		}
	}

	.qadpt-popup-title {
		text-align: center;
		font-size: 16px;
		font-weight: 600;
		padding: 0 10px;
	}

	.qadpt-warning {
		// width: 280px;
		font-size: 14px;
		// margin-top: 10px;
		// min-height: 60px;
		padding: 20px;
		border-radius: 4px;
		text-align: center;
		.qadpt-delete-popup-bold {
			// display: block;
			// overflow: hidden;
			// text-overflow: ellipsis;
			font-weight: 600;
		}
	}
	.qadpt-buttons {
		display: flex;
		justify-content: space-between;
		min-height: 40px;
		padding: 10px !important;
		position: relative;
		bottom: 0;
		border-top: 1px solid var(--border-color);
		.qadpt-cancel-button,
		.qadpt-conform-button {
			padding: var(--button-padding) !important;
			line-height: var(--button-lineheight) !important;
			font-size: 14px;
			border-radius: 4px;
		}

		.qadpt-cancel-button {
			background: var(--white-color);
		}

		.qadpt-conform-button {
			color: var(--white-color);
			border: none;
		}
	}
	&.qadpt-danger {
		.qadpt-icon {
			background-color: #e4b6b0;
			color: var(--error-color);
		}
		.qadpt-warning {
			overflow-x: hidden;
			overflow-y: auto;
			max-height: 135px;
		}
		.qadpt-conform-button {
			background-color: var(--error-color);
		}
		.qadpt-cancel-button {
			border: 1px solid;
			color: var(--error-color);
		}
	}
	&.qadpt-success {
		.qadpt-icon {
			background-color: rgba(95, 158, 168, 0.2);
			color: var(--button-bg-color);
		}
		.qadpt-warning {
			overflow-x: hidden;
			overflow-y: auto;
			max-height: 145px;
		}
		.qadpt-conform-button {
			background-color: var(--button-bg-color);
		}
		.qadpt-cancel-button {
			border: 1px solid var(--button-bg-color);
			color: var(--button-bg-color);
		}
	}
	&.qadpt-deltrigger {
		> div:first-child {
			justify-content: flex-start !important;
		}
		.qadpt-popup-title {
			border-bottom: 1px solid var(--border-color);
			padding: 15px !important;
		}
		.qadpt-warning {
			height: 90px;
			align-content: center;
		}
		.qadpt-buttons {
			padding: 15px !important;
		}
	}
}

.qadpt-midpart .qadpt-content-block {
	&.qadpt-multilingual {
		width: calc(100% - 22px);
		.qadpt-right-part {
			width: 280px;
			margin-right: -10px;
		}

		.qadpt-memberButton {
			margin-right: 10px;
		}
		.qadpt-filters {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px;

			.qadpt-filter-left {
				display: flex;
				gap: 20px;

				.qadpt-select-form {
					min-width: 170px;
					max-width: 200px;
					.MuiInputLabel-outlined.MuiInputLabel-shrink {
						top: 7px !important;
					}
				}
			}

			.qadpt-filter-right {
				display: flex;
				justify-content: flex-end;
				width: 180px;
			}
		}
		.qadpt-language-selection {
			.qadpt-headers {
				display: flex;
				align-items: center;
				place-content: center;
				gap: 20px;
				.qadpt-lang-left,
				.qadpt-lang-rgt {
					width: 50%;

					svg:first-of-type {
						width: 16px;
						height: 16px;
						color: #000;
					}
					.MuiSelect-select {
						padding-left: 0 !important;
					}
				}

				.qadpt-lang-left {
					background: var(--grid-border-color);

					.MuiInputBase-root.MuiInput-root {
						margin-top: 0 !important;
					}

					ul {
						padding-left: 0;
					}
				}

				.qadpt-lang-rgt {
					.MuiInputBase-root.MuiOutlinedInput-root {
						padding-left: 0 !important;
					}
					.MuiInputLabel-outlined.MuiInputLabel-shrink {
						top: 7px !important;
					}
				}
			}
			.qadpt-language-section {
				display: flex;
				// 	align-items: center;
				// place-content: center;
				max-height: 300px;
				overflow: hidden scroll;
				// padding-top: 40px;
				margin-top: 5px;
				gap: 20px;
				.MuiBox-root {
					width: 50%;
				}
				.qadpt-sec-left,
				.qadpt-sec-right {
					ul {
						padding-left: 0 !important;
						margin-top: 0 !important;
						li {
							display: block;
							white-space: nowrap;
							line-height: 16px;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}
				.qadpt-sec-left {
					background: var(--grid-border-color);
				}
			}
		}
	}
}

.qadpt-page-content .qadpt-settings-content .qadpt-settings-page .usr-list-page {
	.qadpt-language-selection .qadpt-language-section .MuiBox-root {
		padding: 0px !important;
	}
}

.qadpt-mngpwd-popup {
	.MuiPaper-root.MuiPaper-elevation {
		width: calc(100vh - 200px);
		min-height: 300px;
	}
	.MuiDialogTitle-root {
		font-size: 18px !important;
		border-bottom: 1px solid var(--border-color);
		padding: 15px;
		font-weight: 600;
	}
	.MuiDialogContent-root {
		padding: 15px !important;
	}
	.qadpt-lang-subhead {
		font-size: 16px !important;
		margin-bottom: 10px;
	}
	.qadpt-primary-language-row {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.qadpt-primary-lang {
			width: 260px;
			input {
				padding: 0 !important;
			}
		}
		.qadpt-add-button {
			background-color: var(--button-bg-color) !important;
			padding: var(--button-padding) !important;
			line-height: var(--button-lineheight) !important;
		}
	}
	.qadpt-lang-dropdown {
		display: flex;
		align-items: center;
		place-content: end;
		width: 100%;
		.MuiFormControl-root {
			margin-right: 0px;
			width: 200px;
		}
		.MuiInputLabel-outlined.MuiInputLabel-shrink {
			top: 7px !important;
		}
	}
	.qadpt-second-lang {
		width: 400px;
		input {
			padding: 0 !important;
		}
	}
	.qadpt-langsave {
		color: var(--white-color) !important;
		padding: var(--button-padding) !important;
		line-height: var(--button-lineheight) !important;
		&.qadpt-langsave-active {
			background-color: var(--button-bg-color) !important;
		}

		&.qadpt-langsave-inactive {
			background-color: #ccc !important;
		}
	}
	.MuiDialogActions-root {
		padding: 15px;
		border-top: 1px solid var(--border-color);
		.qadpt-langclose {
			border: 1px solid var(--button-bg-color) !important;
			color: var(--button-bg-color) !important;
			padding: var(--button-padding) !important;
			line-height: var(--button-lineheight) !important;
		}
	}
}

.qadpt-web {
	background: var(--content-background-color);
	width: calc(100% - 200px);
	position: relative;
	max-width: 100%;
	overflow-x: hidden;
	left: 220px;
	top: 10px;
	.MuiDataGrid-cell:nth-child(2) {
		width: 40% !important;
		max-width: 40% !important;
		min-width: 40%;
		font-weight: 600;
	}
	.MuiDataGrid-cell:nth-child(3) {
		width: 40% !important;
		max-width: 40% !important;
		min-width: 40%;
	}
	.MuiDataGrid-cell:nth-child(4) {
		width: 20% !important;
		max-width: 20% !important;
		min-width: 20%;
	}

	.MuiDataGrid-root {
		border: none;
	}
	.qadpt-webcontent {
		padding: 0 20px;
		height: calc(100vh - 37px);
		.qadpt-head {
			border-bottom: none !important;
		}
		.qadpt-tabs-container {
			border-bottom: 1px solid #ccc;
			display: flex;
			justify-content: space-between;
			button {
				text-transform: capitalize;
			}
			.Mui-selected {
				font-weight: 600;
				color: #000;
			}
			.MuiTabs-indicator {
				background-color: var(--button-bg-color);
			}
		}
		.qadpt-websearch-container {
			display: flex;
			justify-content: flex-end;
			.qadpt-websearch {
				display: flex;
				align-items: center;
				border-radius: 10px;
				.MuiOutlinedInput-root {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					padding: 0 !important;
					border-radius: 10px !important;
				}
				.MuiInputBase-input {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					padding: 10px 15px 10px 0 !important;
					font-size: 16px;
				}
				.MuiInputAdornment-root {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					margin: 0 8px;
				}
				button {
					padding: 0 !important;
					svg {
						margin: 0 !important;
						height: 20px;
						width: 20px;
					}
				}
			}
		}

		.qadpt-webgird,
		.qadpt-webgrd {
			height: calc(100vh - 170px);
			.MuiDataGrid-main {
				--DataGrid-topContainerHeight: 40px !important;
			}
			.MuiDataGrid-filler {
				--rowBorderColor: transparent !important;
			}
			.MuiDataGrid-columnHeaders div[role="row"] {
				max-width: calc(100% - 30px);
			}
			.MuiDataGrid-row {
				border: 0.5px solid #ececec;
				background-color: var(--white-color);
				border-radius: 8px;
				margin-bottom: 10px;
				max-width: calc(100% - 30px);
				--rowBorderColor: transparent !important;
				.MuiDataGrid-cell {
					padding: 0 15px !important;
				}

				// max-height: 36px !important;
				// min-height: 36px !important;
				// --height: 36px !important;
				// margin-top: 3px !important;
				// margin-bottom: 3px !important;
				&:hover {
					background-color: rgba(0, 0, 0, 0.04);
				}
			}

			.MuiDataGrid-columnHeaders {
				.MuiDataGrid-columnHeaderTitleContainerContent .MuiDataGrid-columnHeaderTitle {
					font-weight: 600;
					font-size: 14px;
				}
			}
			.MuiDataGrid-cell {
				border-bottom: none;
			}
			.MuiTablePagination-root {
				// 	width: 100%;
				// 	display: flex;
				// 	align-items: center;
				//   }
				//   .MuiTablePagination-displayedRows{
				// 	display: flex;
				// 	place-content: center;
				// 	width: 197%;
				//   }
				//   .MuiTablePagination-input{
				// 	border: 1px solid #ccc;
				// 	border-radius: 10px;
				// 	.MuiInputBase-input{
				// 		padding-right: 24px !important;
				// 	}
				.MuiSelect-select.MuiTablePagination-select {
					padding-right: 24px !important;
				}
			}
			.MuiDataGrid-cell button {
				border: 1px solid #ccc;
				border-radius: 4px;
				height: 26px;
				width: 26px;
				margin-right: 5px;
				margin-top: -5px;
				img,
				svg {
					zoom: 0.7;
				}
			}
			&.qadpt-anngrd {
				.MuiDataGrid-columnHeader:nth-child(2) {
					width: 40% !important;
					max-width: 40% !important;
					min-width: 40%;
				}
				.MuiDataGrid-columnHeader:nth-child(3) {
					width: 40% !important;
					max-width: 40% !important;
					min-width: 40%;
				}
				.MuiDataGrid-columnHeader:nth-child(4) {
					width: 20% !important;
					max-width: 20% !important;
					min-width: 20%;
				}
			}
		}
		.qadpt-setting-title {
			.qadpt-titsec-grid {
				// margin-bottom: -2px;
				// margin-top: 3px;
				button {
					padding: 5px;
					margin: 0 -10px;
				}
			}

			.qadpt-back-text {
				cursor: pointer;
				position: relative;
				left: 10px;
			}
		}
		.qadpt-titsec {
			margin-bottom: 16px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.qadpt-name-box {
				display: flex;
				align-items: center;
				h5 {
					display: block;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					max-width: calc(100vh - 90px);
				}
			}
			.qadpt-action-btn {
				border-radius: 15px;
				text-transform: none;
				font-weight: 600;
				color: var(--button-bg-color);
				border-color: var(--button-bg-color);

				&.qadpt-share {
					span {
						margin-right: 0 !important;
						margin-left: 0 !important;
					}
				}

				&.qadpt-action-btn-primary {
					background-color: var(--button-bg-color) !important;
					color: var(--white-color);

					&.qadpt-unpublish {
						background-color: var(--error-color) !important;
					}
				}
			}
		}
		.qadpt-content {
			display: flex;
			width: 100%;
			gap: 10px;
			// margin-top: 20px;
			// .MuiGrid-root.MuiGrid-item{
			// 	margin-top: 10px;
			// }
			.qadpt-label {
				font-size: 14px;
				font-weight: 600;
				display: block;
				margin-bottom: 10px;
			}
			.qadpt-sublabel {
				font-size: 14px;
			}
			.qadpt-card {
				border-radius: 15px;
				margin-top: 10px;
				&.qadpt-buildcard {
					min-height: 420px;
					max-height: 420px;
					// margin-bottom: 20px;
				}
				&.qadpt-trigger {
					line-height: 0;
				}
				&.qadpt-rev {
					margin-bottom: 20px;
				}
			}
			.qadpt-set-left {
				width: 40%;
				.qadpt-description {
					.MuiPaper-root.MuiCard-root {
						padding: 0 !important;
						// margin-top: 0 !important;
						.MuiCardContent-root {
							padding-bottom: 15px !important;
						}
						textarea {
							width: 100%;
							height: 100px;
							padding: 10px;
							border: 1px solid #ccc;
							border-radius: 8px;
							font-size: 14px;
							line-height: 1.5;
							overflow: auto;
						}
					}
				}
				.qadpt-buildsteps {
					.qadpt-build-content {
						overflow-x: hidden;
						height: calc(100vh - 250px);
					}
					.MuiFormControl-root {
						background-color: #eef5f5;
					}
					.qadpt-steps {
						margin-top: 8px;
						opacity: 0.5;
						pointer-events: none;
					}
				}
			}
			.qadpt-set-right {
				width: 60%;
				.qadpt-auto-trigger .MuiGrid-root.MuiGrid-container {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.Mui-checked {
						color: var(--button-bg-color);
					}
					.MuiSwitch-thumb {
						color: var(--button-bg-color);
					}
				}
				.qadpt-page-target {
					.qadpt-header-container {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding-bottom: 10px;
						border-bottom: 1px solid #ccc;
						.qadpt-label-sublabel {
							display: flex;
							flex-direction: column;
						}

						.qadpt-add-target-btn {
							border-radius: 15px !important;
							color: var(--button-bg-color) !important;
							border: 1px solid var(--button-bg-color) !important;
							text-transform: none !important;
							// font-size: 16px !important;
							font-weight: 600 !important;
							&.save {
								padding: 8px 12px;
								margin-left: 10px;
							}
						}
					}

					.qadpt-conditions {
						max-height: calc(110vh - 470px);
						overflow-x: hidden;
						padding: 10px;

						.MuiGrid-root.MuiGrid-container {
							margin-top: 0 !important;
							.MuiGrid-root.MuiGrid-item {
								padding-top: 0px !important;
								padding-bottom: 5px;
								&.qadpt-btngrid {
									display: flex;
									align-items: center;
									place-content: center;

									button {
										border: 1px solid #f68e8e;
										padding: 5px !important;
										border-radius: 10px !important;
										margin-top: 27px;
									}
									&.qadpt-error-btn {
										button {
											margin-top: 10px !important;
										}
									}
								}
								fieldset {
									height: 40px !important;
								}
								.qadpt-field-label {
									font-size: 13px;
									color: #afafaf;
								}
								.Mui-error {
									color: #e6a957;
									// &.MuiOutlinedInput-root{
									// 	border-color: #e6a957 !important;
									// }
								}
							}
							.MuiGrid-root.MuiGrid-item:last-child {
								padding-left: 0 !important;
							}
						}
						//  .MuiInputLabel-outlined.MuiInputLabel-shrink{
						// 	    top: -10px !important;
						// 	left: -12px;
						//  }

						.qadpt-operator {
							display: flex;
							align-items: center;
							place-content: center;
							flex-direction: row;
							justify-content: flex-end;
							width: 100%;
							gap: 8px;
							top: 10px;
							span {
								color: #7b7b7b;
								font-size: 14px;
							}
							.MuiInputBase-root {
								width: 80px;
								border: 1px solid #ccc;
								height: 30px;
								background: #ebfeff;
							}
							fieldset {
								border: 0;
							}
						}
					}
				}
				.qadpt-frequency {
					.qadpt-freqselect {
						white-space: nowrap;
						font-size: 12px;
					}
					.MuiGrid-root.MuiGrid-item:last-child {
						.MuiFormControl-root {
							max-width: 90px;
							min-width: 90px;
							margin-left: 0 !important;
						}
					}
				}
				.qadpt-rev-publish {
					.qadpt-gridleft {
						border-right: 1px solid #ccc;
						.MuiFormControl-root .MuiInputBase-root {
							width: 80%;
						}
					}
					.qadpt-gridright {
						.MuiFormControl-root .MuiInputBase-root {
							width: 80%;
						}
					}

					.MuiGrid-container {
						.MuiGrid-item {
							.MuiTypography-root {
								font-size: 14px;
								&:first-child {
									font-weight: 600;
								}
							}
						}
						.MuiFormControl-root span {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}
.qadpt-webclonepopup {
	.MuiPaper-root.MuiDialog-paper {
		border-radius: 4px;
		width: 400px;
	}
	.qadpt-title-sec {
		padding: 15px;
		border-bottom: 1px solid var(--border-color);
		.qadpt-title {
			font-size: 18px;
			font-weight: 600;
			padding: 0px !important;
			display: block;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			width: calc(100px + 190px);
		}
		.qadpt-close {
			position: absolute !important;
			top: 10px;
			right: 20px;
			// color: #ccc;
			svg {
				font-size: 18px;
			}
		}
	}

	.MuiDialogContent-root {
		padding: 15px !important;
		min-height: 100px;
		.clone-fld .MuiInputLabel-outlined.MuiInputLabel-shrink {
			top: 7px !important;
		}
	}
	.MuiDialogActions-root {
		border-top: 1px solid var(--border-color);
		padding: 15px;
		.MuiButton-root {
			background-color: var(--button-bg-color);
			border-radius: 4px;
			padding: var(--button-padding) !important;
			line-height: var(--button-lineheight) !important;
		}
	}
}

.qadpt-createpopup {
	width: calc(100vh - 200px);
	max-height: 400px;
	min-height: 310px;
	// bottom: calc(100% - 180px);
	// left: calc(100% - 200px);
	// position: fixed !important;
	background: var(--white-color) !important;
	box-shadow: 0 3px 8px #000000;
	border-radius: 8px;
	.qadpt-title-sec {
		padding: 15px;
		border-bottom: 1px solid var(--border-color);
		.qadpt-title {
			font-size: 18px;
			font-weight: 600;
		}
		.qadpt-sub-title {
			font-size: 12px;
			line-height: initial;
		}
	}

	// form {
	// 	height: 184px;
	// 	.qadpt-createfield {
	// 		position: relative;
	// 		top: 25px;
	// 		font-size: 14px;
	// 		font-weight: 600;
	// 		&.qadpt-error {
	// 			color: var(--error-color);
	// 			line-height: 12px !important;
	// 		}
	// 		// .qadpt-acctfield {
	// 		// 	width: 370px;

	// 		// 	.MuiFormHelperText-root {
	// 		// 		color: var(--error-color);
	// 		// 	}
	// 		// 	.MuiInputBase-root-MuiOutlinedInput-root {
	// 		// 		border-radius: 4px !important;
	// 		// 	}
	// 		// }
	// 	}
	// }
	.qadpt-createflds {
		padding: 15px;
		label {
			font-size: 14px;
			font-weight: 600;
		}
	}

	.qadpt-save-btn {
		text-align: end;
		padding: 15px;
		border-top: 1px solid var(--border-color);
		button {
			background-color: var(--button-bg-color);
			color: var(--white-color);
			text-transform: capitalize;
			padding: var(--button-padding) !important;
			line-height: var(--button-lineheight) !important;
		}
	}
}
.qadpt-multi-drpdwn {
	.MuiPaper-root {
		max-width: 500px;
		left: 640px !important;
		min-width: auto !important;
		width: auto !important;
	}
	li {
		white-space: normal;
		word-break: break-word;
	}
}
.qadpt-datepicker {
	.MuiCalendarPicker-root {
		max-height: 283px !important;
		overflow-y: hidden;
		.MuiYearPicker-root {
			max-height: 234px !important;
			.PrivatePickersYear-root button {
				line-height: 1 !important;
				margin: 4px 0 !important;
				height: 32px !important;
				width: 69px !important;
			}
		}
	}
	.MuiPickersCalendarHeader-root {
		margin-top: 8px !important;
	}
}

.qadpt-rolemenu {
	max-height: 270px !important;
	margin-top: 5px !important;
	overflow-y: auto !important;
	max-width: 460px !important;
}

.qadpt-svg {
	padding: 0 !important;
}
.qadpt-page-content .usr-list-page .qadpt-midpart .qadpt-content-box {
	.qadpt-setting-grd,
	.qadpt-account-grd {
		.qadpt-grd-act {
			padding: 10px !important;
			display: flex;
			align-items: center;
			gap: 2px;
		}
		.qadpt-grd-action {
			padding: 15px !important;
			display: flex;
			align-items: center;
			gap: 2px;
			place-content: center;
		}
	}
}

.MuiButtonBase-root.MuiMenuItem-root {
	margin: 0 10px;
	padding: 10px;
	&.Mui-selected,
	&:hover {
		border-radius: 12px;
		margin: 0 10px;
		padding: 10px;
	}
}

.qadpt-homepg {
	height: calc(100vh - 37px);
	width: calc(100% - 200px);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	left: 220px;
	.qadpt-imgsec {
		img {
			height: 44vh;
		}
	}
	.qadpt-des {
		text-align: center;
		margin-top: 50px;
		width: 450px;
	}
	.qadpt-msg {
		color: #8d8d8d;
		font-size: 14px;
	}
	.qadpt-usrnm {
		font-weight: 600;
		font-size: 19px;
		margin-top: 5px;
		display: flex;
		align-items: center;
		place-content: center;
		gap: 5px;
	}
	.qadpt-statmsg {
		font-size: 14px;
		margin-top: 10px;
	}
}
