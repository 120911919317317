.qadpt-account {
	margin-right: 6px;
	background-color: var(--white-color);
	border-radius: 6px;
	height: calc(100vh - 90px);

	&.sidebaropen {
		margin-left: 190px;
	}
	.qadpt-accttitles {
		top: 1px;
		position: relative;
		left: -420px;
		h1 {
			position: relative;
			right: 28px;
		}
		.qadpt-hdrsideon {
			top: -15px;
			position: relative;
			left: 440px;
			font-weight: bold;
		}
		.qadpt-hdrsideoff {
			top: -15px;
			position: relative;
			left: 50px;
			font-weight: bold;
		}
	}
}
.accountlistGrid {
	height: calc(100vh - 195px);
	width: calc(100% - 20px);
	margin-top: 2px;
	top: -17px;
	position: relative;
	display: flex;
	flex-direction: column;

	&.sidebarOpen {
		margin-left: 12px;
	}

	&.sidebarClosed {
		margin-left: 1px;
	}

	.qadpt-searchfilter {
		right: 20px;
		float: right;
		width: 200px;
		margin-bottom: 8px !important;

		input {
			padding: 10px 14px;
		}

		// Combine sx styles here
		.MuiFormHelperText-root {
			color: inherit;

			// Change to red if there are errors
			&.error {
				color: var(--error-color);
			}
		}

		.MuiOutlinedInput-root {
			padding: 0px;
			border-radius: 20px;
		}

		.MuiInputBase-input {
			height: 1em;
			padding-left: 0px;
		}
	}
}
.qadpt-accountCreateButtonPosition {
	top: -163px;
	position: relative;
	//left: -350px;
}

.qadpt-accountcreatepopup,
.qadpt-accounteditpopup {
	z-index: 99;
	width: 400px !important;
	max-height: 250px;
	top: 200px;
	right: 30%;
	position: fixed !important;
	background: var(--white-color) !important;
	box-shadow: 0 3px 8px #000000;
	border-radius: 4px;
	.qadpt-title-sec {
		padding: 15px;
		border-bottom: 1px solid var(--border-color);
		.qadpt-title {
			font-size: 18px;
			font-weight: 600;
		}
	}

	.qadpt-accountcreatefield {
		position: relative;
		font-size: 14px;
		font-weight: 600;
		padding: 15px;
		&.qadpt-error {
			color: var(--error-color);
		}
		.qadpt-acctfield {
			width: 100%;

			.MuiFormHelperText-root {
				color: var(--error-color);
				line-height: 12px;
			}
			.MuiInputBase-root-MuiOutlinedInput-root {
				border-radius: 4px !important;
			}
		}
	}
	form {
		height: calc(100% - 100px);
	}
	.qadpt-account-buttons {
		padding: 15px;
		border-top: 1px solid var(--border-color);
		text-align: end;
		.qadpt-save-btn {
			background-color: var(--button-bg-color);
			color: var(--white-color);
			text-transform: capitalize;
			padding: var(--button-padding) !important;
			line-height: var(--button-lineheight) !important;
			&.invalid {
				background-color: #a5c3c5;
				pointer-events: none;
			}
		}
	}
}

.qadpt-roleeditpopup,
.qadpt-roledeletepopup {
	z-index: 9;
	width: 500px !important;
	// padding: 20px;
	position: fixed !important;
	background: var(--white-color) !important;
	box-shadow: 0 3px 8px #000000;
	border-radius: 4px;

	&.qadpt-roleeditpopup {
		max-height: 500px;
		top: 140px;
		right: 420px;
	}

	&.qadpt-roledeletepopup {
		width: 395px !important;
		height: 23%;
		top: 252px;
		right: 30%;
		padding: 10px;
	}
	.qadpt-title-sec {
		padding: 15px;
		border-bottom: 1px solid var(--border-color);
		.qadpt-title {
			font-size: 18px;
			font-weight: 600;
			margin-top: 0;
		}
	}

	.qadpt-subtitle {
		font-size: 14px;
		padding: 10px 20px 20px 5px;
	}

	.qadpt-addrole {
		height: calc(100% - 75px);
		overflow: auto;
		padding: 0 15px 15px 15px;
		.MuiGrid-container {
			margin-top: 0 !important;
		}
		.MuiGrid-item .MuiInputLabel-outlined.MuiInputLabel-shrink {
			top: 7px !important;
		}
	}

	.qadpt-role-buttons {
		position: relative;
		bottom: 0;
		text-align: right;
		padding: 15px;
		border-top: 1px solid var(--border-color);

		button {
			margin-left: 16px;
			border-radius: 4px;
			border: 1px solid var(--button-bg-color);
			color: var(--button-bg-color);
			text-transform: capitalize;
			padding: var(--button-padding) !important;
			line-height: var(--button-lineheight) !important;
		}

		.qadpt-conform-button {
			background-color: var(--button-bg-color) !important;
			color: var(--white-color) !important;

			&.qadpt-disabled {
				background-color: #a5c3c5 !important;
				border: none;
			}
		}
	}
}

.qadpt-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9;
	pointer-events: auto;
}

.qadpt-iconcloseaccountcreate {
	position: absolute;
	top: 20px;
	cursor: pointer;
	right: 15px;
	width: 15px;
}
.qadpt-accountCreateButtonsdiv {
	display: flex;
	gap: 150px;
	position: relative;
	top: -25px;
	left: 60px;
}
.qadpt-accountCreatesaveButtonsdiv {
	position: relative;
	right: -280px;
	top: 95px;
	width: 80px;
}

.qadpt-accountcreateButton {
	position: absolute;
	right: 20px;
	z-index: 99;
	top: 55px;
	background-color: var(--button-bg-color);
	border: none;
	border-radius: 15px;
	color: var(--white-color);
	cursor: pointer;
	font-size: 14px;
	padding: 10px 14px 10px 14px;
	height: 44px;
	width: 183px;
}
.qadpt-buttoncolor {
	color: black;
}
.accountlistGrid {
	height: calc(100vh - 195px);
	width: calc(100% - 20px);
	margin-top: 2px;
	top: -17px;
	position: relative;
	display: flex;
	flex-direction: column;

	&.sidebarOpen {
		margin-left: 12px;
	}

	&.sidebarClosed {
		margin-left: 1px;
	}
}
.qadpt-editaccounttexthdr {
	position: relative;
	bottom: 60px;
	right: 100px;
}
.qadpt-editaccountfields {
	position: relative;
	bottom: 70px;
	right: 10px;
}
.qadpt-editaccountbuttonsposition {
	position: relative;
	bottom: 70px;
	display: flex;
	gap: 120px;
}
.qadpt-editaccountcancelbuttons {
	position: absolute;
	right: 300px;
	z-index: 99;
	top: 0px;
	background-color: #007bff;
	border: none;
	border-radius: 5px;
	color: var(--white-color);
	cursor: pointer;
	font-size: 14px;
	padding: 7px 21px;
}
.qadpt-editaccountsavebuttons {
	position: absolute;
	right: 60px;
	z-index: 99;
	top: 0px;
	background-color: #007bff;
	border: none;
	border-radius: 10px;
	color: var(--white-color);
	cursor: pointer;
	font-size: 14px;
	padding: 10px 25px;
}
.qadpt-accountalert {
	top: 40px !important;
	width: 400px;
	left: 55% !important;
	z-index: 999999 !important;
	.MuiPaper-root.MuiAlert-root {
		width: 100%;
	}
}
