/* TeamSettings.module.scss */

.smoothtransition {
	transition: margin-left 0.3s ease;
}

.team-settings {
	padding: 20px;
}
.UserRoleTitle {
	font-weight: 700;
	font-size: 23px;
	margin-top: -14px;
}
.Loaderstyles {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}
.LoaderSpinnerStyles {
	width: 50px;
	height: 50px;
}


.selectaccountGlobal {
	top: 25px;
	right: 51px;
	left: 567px;
	width: 200px;
}
.selectaccountGloballeft {
	top: 25px;
	right: 51px;
	left: 581px;
	width: 200px;
}
.addUserroleglobal {
	right: 35px;
	height: 34px;
	top: 27px;
	font-size: x-small;
	left: 586px;
}
.addUserrolegloballeft {
	right: 35px;
	height: 34px;
	top: 27px;
	font-size: x-small;
	left: 611px;
}

.header {
	background-color: #a3c2c2;
}

.searchContainer {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 16px;
}

.selectAccount {
	max-width: 200px;
	right: 51px;
}
.MuiOutlinedInput-input {
	padding: 0;
	margin-top: 5px;
	margin-left: 31px;
}

.MuiOutlinedInput-notchedOutline {
	border-radius: 5px;
	height: 35px;
}

.MuiInputLabel-outlined {
	top: -6px !important;
	&.MuiInputLabel-shrink {
		top: 0 !important;
	}
}

.MuiAutocomplete-inputRoot {
	height: 35px;
}

.addUserRoleButton {
	right: 35px;
	height: 34px;
	top: 13px;
	font-size: x-small;

	.MuiSvgIcon-root {
		margin-right: 8px;
	}
}

.dataGridContainer {
	height: 344px;
	width: 100%;
	margin-top: 16px;

	.header {
		background-color: #a3c2c2;
	}
}
/* setting styles starts*/
.qadpt-setsidebar {
	top: 20px;
	position: relative;
	padding: 10px 20px;

	// &.sidebar-open {
	// 	margin-left: 255px;
	// }

	// &.sidebar-closed {
	// 	margin-left: 39px;
	// }
	.qadpt-sidebarlist {
		font-size: 12px;
		font-weight: 600;
		color: #8a8a8a;
		text-transform: uppercase;
	}
	// .qadpt-sidebarele {
	// 	width: 110px;
	// 	gap: 4px;
	// }
	.qadpt-sidebarinput {
		padding: 3px 22px;
		margin-bottom: 4px;
		border-radius: 6px;
		background-color: transparent;

		&.active {
			background-color: rgba(95, 158, 160, 1);
			color: #ffffff;
			.MuiTypography-root {
				color: #ffffff;
			}
		}

		&:hover {
			background-color: rgba(0, 0, 0, 0.08);
		}
	}
	.qadpt-sidebarval {
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		letter-spacing: 0.3px;
		text-align: left;
		margin-left: -2px;
		color: #202224;

		&.active {
			color: #ffffff;
		}
	}
}
/* setting styles ends */

